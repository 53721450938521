import FerienwohnungIcon from '../../assets/icons/accommodation-icons/ferienwohnung.svg'
import FerienhausIcon from '../../assets/icons/accommodation-icons/ferienhaus.svg'
import VillaIcon from '../../assets/icons/accommodation-icons/villa.svg'
import FincaIcon from '../../assets/icons/accommodation-icons/finca.svg'
import BungalowIcon from '../../assets/icons/accommodation-icons/bungalow.svg'
import CampingplatzIcon from '../../assets/icons/accommodation-icons/campingplatz.svg'

export const getIconFromId = (id: string) => {
    switch (id) {
        case '1':
            return FerienwohnungIcon
        case '2':
            return FerienhausIcon
        case '3':
            return VillaIcon
        case '4':
            return FincaIcon
        case '5':
            return BungalowIcon
        case '19':
            return CampingplatzIcon
        case '20':
            return BungalowIcon
        default:
            return FerienwohnungIcon
    }
}
