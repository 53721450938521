import React, { FC, memo } from 'react'

import { TypicalAccommodationsSectionTransport } from '../../transports/region-landing-page-transport'
import ExternalLink from '../common/external-link'
import {
    Container,
    IconBox,
    Image,
    MenuContainer,
    MenuWrapper,
    Section,
    SubtitleSpan,
    Text,
    TextWrapper,
    TitleSpan,
} from './accmmodation-section-style'
import { useTranslation } from '../../hooks/locale'
import { getIconFromId } from '../../models/landing-page/utils'
import { AnyStyledComponent } from 'styled-components'

interface Props {
    typicalAccommodationsSection: TypicalAccommodationsSectionTransport
}

const AccommodationSection: FC<Props> = ({ typicalAccommodationsSection }) => {
    const { t } = useTranslation()

    const { heading, items } = typicalAccommodationsSection

    return (
        <Section>
            <TextWrapper>
                <Text>{heading}</Text>
            </TextWrapper>
            <Container>
                {items.map(item => (
                    <MenuWrapper key={item.link}>
                        <ExternalLink as={MenuContainer} title={item.linkTitle} href={item.link} noCrawl={item.noCrawl}>
                            <IconBox>
                                <Image as={getIconFromId(item.id) as AnyStyledComponent} />
                            </IconBox>
                            <TitleSpan>{item.title}</TitleSpan>
                            <SubtitleSpan>
                                {item.count} {item.count === 1 ? t('accommodation') : t('accommodations')}
                            </SubtitleSpan>
                        </ExternalLink>
                    </MenuWrapper>
                ))}
            </Container>
        </Section>
    )
}

export default memo(AccommodationSection)
