import styled, { css } from 'styled-components'

interface CardProps {
    borderRadius?: string
    borderWidth?: string
    borderStyle?: string
    borderColor?: string
}

export const Container = styled.div<CardProps>(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        border-width: ${props => props.borderWidth ?? theme.typography.border.width.xxxThin};
        border-style: ${props => props.borderStyle ?? props.theme.typography.border.style.solid};
        border-color: ${props => props.borderColor ?? props.theme.colors.tfwBlackOpacity10};
        box-sizing: border-box;
        border-radius: ${props => props.borderRadius ?? props.theme.typography.border.radius.xLarge};
    `,
)

export const ImageContainer = styled.section`
    position: relative;
`
export const TopLeftContainer = styled.div(
    () => css`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    `,
)
export const TopRightContainer = styled.div(
    ({ theme }) => css`
        position: absolute;
        top: ${theme.spacing.small};
        right: ${theme.spacing.small};
        z-index: 10;
    `,
)
interface SnippetProps {
    padding?: string
}
export const InformationContainer = styled.section<SnippetProps>`
    padding: ${props => props.padding ?? props.theme.spacing.medium};
    display: flex;
    flex-direction: column;
`
export const BodyTopContainer = styled.div``
export const BodyBottomContainer = styled.div``
