import { Slide } from 'pure-react-carousel'
import BestDestinationCard from '../../app-components/best-destination-card'
import React, { FC, memo } from 'react'
import { PopularDestinationTransport } from '../../../transports/region-landing-page-transport'

interface Props {
    regions: PopularDestinationTransport[]
}

const Slider: FC<Props> = ({ regions }) => {
    return (
        <>
            {regions.map((region, regionIndex) => (
                <Slide index={regionIndex} key={region.url}>
                    <BestDestinationCard
                        url={region.url}
                        imageUrl={region.imageUrl}
                        title={region.title}
                        count={region.count}
                        noCrawl={region.noCrawl}
                    />
                </Slide>
            ))}
        </>
    )
}

export default memo(Slider)
