import React, { FC, memo } from 'react'

import { PopularDestinationTransport } from '../../../transports/region-landing-page-transport'
import { Card } from 'react-components'
import {
    AccommodationContainer,
    Container,
    FooterContainer,
    ImageContainer,
    InnerContainer,
    RegionTitleContainer,
    SeoHref,
} from './best-destination-card-styles'
import { useTranslation } from '../../../hooks/locale'
import NextImage from '../../common/next-image'
import { preventDefaultHandler } from '../../utils'

const BestDestinationCard: FC<PopularDestinationTransport> = ({ url, title, imageUrl, count, noCrawl }) => {
    const { t } = useTranslation()

    return (
        <Container href={url} noCrawl={noCrawl} target={'_blank'} title={url}>
            <Card borderRadius={'0.75rem'}>
                <InnerContainer>
                    <ImageContainer>
                        <NextImage src={imageUrl} alt={title} priority />
                    </ImageContainer>
                    <FooterContainer>
                        <RegionTitleContainer>
                            <SeoHref
                                href={url}
                                noCrawl={noCrawl}
                                target={'_blank'}
                                onClick={preventDefaultHandler}
                                title={title}
                                autoRedirect={false}
                            >
                                {title}
                            </SeoHref>
                        </RegionTitleContainer>
                        <AccommodationContainer>{`${count} ${
                            count === 1 ? t('accommodation') : t('accommodations')
                        }`}</AccommodationContainer>
                    </FooterContainer>
                </InnerContainer>
            </Card>
        </Container>
    )
}

export default memo(BestDestinationCard)
