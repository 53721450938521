import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { ScreenContext } from 'react-components'
import { AnyStyledComponent } from 'styled-components'

import { useTranslation } from '../../hooks/locale'
import {
    DropdownContainer,
    DropDownLabel,
    GreyIconWrapper,
    GuestsChange,
    GuestsChangeButton,
    GuestsValueLabel,
    InputWrapper,
    LabelWrapper,
    NewDropdownContainer,
} from './searchbar-section-styles'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { landingPageEventActions } from '../../services/analytics-service/actions'
import AnalyticsService from '../../services/analytics-service'

import { GuestsIcon } from '../common/icons'
import ChevronDownIcon from '../../assets/icons/chevron-down.svg'
import Plus from '../../assets/icons/plus.svg'
import Minus from '../../assets/icons/minus.svg'

interface Props {
    val: number
    setVal: CallableFunction
    minVal: number
    maxVal: number
    setMinVal: CallableFunction
    setMaxVal: CallableFunction
    sticky: boolean
    url: string
}

const getGuestLabel = (val: number, guest: string, guests: string): string => {
    return `${val === 0 ? guests : val !== 1 ? `${val < 12 ? val : '12+'} ${guests}` : val + ' ' + guest}`
}

const NewDropdown: React.FC<Props> = ({ val, setVal, minVal = 0, maxVal = 12, setMinVal, setMaxVal, sticky, url }) => {
    const { t } = useTranslation()
    const { isMobile } = useContext(ScreenContext)
    const [clicked, setClicked] = useState<boolean>(false)
    const containerRef = useRef(null)
    const debounceCall = useCallback(
        debounce(val => {
            const eventCategory = `${landingPageEventCategories.searchSection.SEARCH_BOX} - ${url}`
            const eventAction = landingPageEventActions.searchSection.searchBox.PERSONS_COUNT
            AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: val.toString() })
        }, 1000),
        [],
    )

    const increaseVal = useCallback(() => {
        if (val < maxVal) {
            setVal(val + 1)
        }
        debounceCall(val)
    }, [setVal, val])

    const decreaseVal = useCallback(() => {
        if (val > minVal) {
            setVal(val - 1)
        }
        debounceCall(val)
    }, [setVal, val])

    const handleOutsideClick = useCallback(
        e => {
            if (containerRef.current && containerRef.current.contains(e.target)) {
                return
            }
            document.removeEventListener('click', handleOutsideClick)
            setClicked(false)
        },
        [setClicked],
    )

    const handleClick = useCallback(
        event => {
            event.stopPropagation()
            setClicked(true)
            document.addEventListener('click', handleOutsideClick)
        },
        [setClicked, handleOutsideClick],
    )

    const guestLabel = useMemo(() => getGuestLabel(val, t('guest'), t('guests')), [val])

    return (
        <DropdownContainer onClick={handleClick} sticky={sticky}>
            <GuestsIcon />
            <InputWrapper>
                <LabelWrapper>{guestLabel}</LabelWrapper>
            </InputWrapper>
            {isMobile && <GreyIconWrapper as={ChevronDownIcon as AnyStyledComponent} />}
            {clicked && (
                <NewDropdownContainer ref={containerRef}>
                    <DropDownLabel>{t('guests')}</DropDownLabel>
                    <GuestsChange>
                        <GuestsChangeButton onClick={decreaseVal} as={Minus as AnyStyledComponent} />
                        <GuestsValueLabel>{val}</GuestsValueLabel>
                        <GuestsChangeButton onClick={increaseVal} as={Plus as AnyStyledComponent} />
                    </GuestsChange>
                </NewDropdownContainer>
            )}
        </DropdownContainer>
    )
}

export default memo(NewDropdown)
