import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const FilterIconImage = styled.svg`
    width: ${props => props.theme.layout.xSmall};
    height: ${props => props.theme.layout.xSmall};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 2.5rem;
        height: 2.5rem;
    }
`
