import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { CarouselIcon, StyledButton, StyledButtonNext } from './styles'
import { ChevronRightIcon } from '../../common/icons'
import { LoadingIcon } from '../top-offers-styles'

interface Props {
    numberOfSlides: number
    visibleSlides: number
    isLoading: boolean
}

const CarouselButtonNext: FC<Props> = ({ numberOfSlides, visibleSlides, isLoading }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return isLoading ? (
        <StyledButton $right>
            <LoadingIcon />
        </StyledButton>
    ) : _currentSlideIndex !== numberOfSlides - visibleSlides && numberOfSlides >= visibleSlides ? (
        <StyledButtonNext $right>
            <CarouselIcon as={ChevronRightIcon as AnyStyledComponent} />
        </StyledButtonNext>
    ) : null
}

export default memo(CarouselButtonNext)
