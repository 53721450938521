import React, { FC, Fragment, memo, useCallback, useContext, useState } from 'react'
import { ScreenContext } from 'react-components'

import LinkBox from './link-box'
import { Container, RegionListContainer, ShowMoreText, Title } from './regions-list-styles'
import { HolidayIdeasSectionTransport } from '../../transports/region-landing-page-transport'
import { useTranslation } from '../../hooks/locale'

interface Props {
    holidayIdeasSection: HolidayIdeasSectionTransport
    locationName?: string
}

const HolidayIdeasSection: FC<Props> = ({ holidayIdeasSection: { heading, items }, locationName }) => {
    const { t } = useTranslation()
    const { isMobile } = useContext(ScreenContext)
    const [isShowMore, setShowMore] = useState(false)
    const defaultItems = 4

    const toggleShowMore = useCallback(() => {
        setShowMore(!isShowMore)
    }, [setShowMore, isShowMore])

    return (
        <Container id={'holiday-item'}>
            <Title>{heading}</Title>
            <RegionListContainer>
                {isMobile &&
                    items.map((holidayIdeaItem, index) => (
                        <Fragment key={holidayIdeaItem.linkTitle}>
                            <LinkBox
                                item={holidayIdeaItem}
                                hidden={index >= defaultItems && !isShowMore}
                                locationName={locationName}
                            />
                        </Fragment>
                    ))}
                {!isMobile &&
                    items.map(holidayIdeaItem => (
                        <LinkBox key={holidayIdeaItem.linkTitle} item={holidayIdeaItem} locationName={locationName} />
                    ))}
            </RegionListContainer>

            {isShowMore && isMobile && <ShowMoreText onClick={toggleShowMore}>{t('showLess')}</ShowMoreText>}
            {!isShowMore && isMobile && <ShowMoreText onClick={toggleShowMore}>{t('showMore')}</ShowMoreText>}
        </Container>
    )
}

export default memo(HolidayIdeasSection)
