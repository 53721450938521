import styled, { css } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint, wideScreenBreakpoint } from 'react-components'
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel'

import { ChevronLeftIcon, ChevronRightIcon } from '../../common/icons'

export const Container = styled.section(
    ({ theme }) => css`
        width: inherit;
        padding: 0 ${theme.spacing.medium} 0;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            padding: 0 ${theme.spacing.large};
        }
    `,
)

export const Title = styled.h2(
    ({ theme }) => css`
        font-family: ${theme.typography.font.family.yummoVerdana};
        font-size: ${theme.typography.font.size.large};
        font-weight: ${theme.typography.font.lato.weight.bold};
        line-height: 1.75rem;
        margin: ${theme.spacing.large} 0 ${theme.spacing.xxSmall};
        color: ${theme.colors.tfwBlack87};
        letter-spacing: -0.021875rem;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: 2.25rem;
            line-height: 2.625rem;
            letter-spacing: -0.0625rem;
        }
    `,
)

export const SubTitle = styled.p(
    ({ theme }) => css`
        font-family: ${theme.typography.font.family.latoVerdana};
        font-size: ${theme.typography.font.size.small};
        line-height: 1.25rem;
        color: ${theme.colors.tfwBlackOpacity50};
        margin: 0;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: ${theme.typography.font.size.medium};
            line-height: 1.5rem;
        }
    `,
)

export const CarouselLeftIcon = styled(ChevronLeftIcon)(
    ({ theme }) => css`
        height: ${theme.layout.xSmall};
        width: ${theme.layout.xSmall};
    `,
)

export const CarouselRightIcon = styled(ChevronRightIcon)(
    ({ theme }) => css`
        height: ${theme.layout.xSmall};
        width: ${theme.layout.xSmall};
    `,
)

export const StyledSlider = styled(Slider)`
    padding: 0;
    ul {
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        will-change: transform;
        min-width: 100%;
    }
`

export const StyledCarouselProvider = styled(CarouselProvider)<{ $customWidth?: boolean }>(
    ({ $customWidth }) => css`
        li {
            list-style: none;
        }
        width: 100%;

        ${$customWidth &&
        `li {
            @media only screen and (min-width: ${tabletBreakpoint.min}px) {
                width: 50% !important;
            }
            @media only screen and (min-width: ${desktopBreakpoint.min}px) {
                width: 33.34% !important;
            }
            @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
                width: 25% !important;
            }
        }`}
    `,
)

export const DestinationsMobileContainer = styled.div`
    display: flex;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
`

export const DestinationsColumn = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledButtonBack = styled(ButtonBack)<{ $left?: boolean; $right?: boolean }>(
    ({ theme, $left, $right }) => css`
        display: none;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            position: absolute;
            top: 45%;
            cursor: pointer;
            left: ${$left ? '0.625rem' : 'unset'};
            right: ${$right ? '0.625rem' : 'unset'};
            transform: translateY(-50%);
            background-color: ${theme.colors.white};
            border-radius: 100%;
            height: ${theme.layout.medium};
            width: ${theme.layout.medium};
            display: flex;
            flex-direction: ${theme.style.flexDirection.column};
            align-items: ${theme.style.alignItems.center};
            justify-content: ${theme.style.justifyContent.center};
            transition: opacity 200ms ease-in;
            z-index: 20;
            box-shadow: 0 0.5rem 0.625rem 0.0625rem #21212123, 0 0.1875rem 0.875rem 0.1875rem #2121210f,
                0 0.25rem 0.635rem 0 #21212119;
            &:hover {
                opacity: 1;
                transition: opacity 200ms ease-out;
            }
        }
    `,
)

export const StyledButtonNext = styled(StyledButtonBack).attrs({ as: ButtonNext })``

export const SliderContainer = styled.div(
    ({ theme }) => css`
        position: ${theme.style.position.relative};
        margin-top: ${theme.typography.font.size.medium};
        overflow: hidden;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin-top: ${theme.typography.font.size.xLarge};
        }

        @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
            margin-right: -${theme.spacing.large};
        }
    `,
)
