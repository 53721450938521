import React, { FC, memo } from 'react'

import ListingSectionTransport from '../../transports/common/listing-section-transport'
import ListingSection from './listing-section'
import { useTopOffersSection } from '../../hooks/listings'

interface Props {
    topOfferListingsSection: ListingSectionTransport
    url: string
}

const TopOffersSection: FC<Props> = ({ topOfferListingsSection, url }) => {
    const { section, getTopOfferListings, isLoading } = useTopOffersSection(topOfferListingsSection)
    return (
        <ListingSection
            listingSection={section}
            getListings={getTopOfferListings}
            isLoading={isLoading}
            url={url}
            sectionId="top-offer-object"
            listingType="RED"
        />
    )
}

export default memo(TopOffersSection)
