import styled from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint, wideScreenBreakpoint } from 'react-components'

export const Container = styled.section`
    width: inherit;
    padding: 0 1rem 2.5rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 1.5rem;
        margin-bottom: 2.5rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: 3.75rem;
    }
`
export const RegionListContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-wrap: ${({ theme }) => theme.style.flexWrap.wrap};
`
export const Title = styled.h2`
    margin: 2.5rem 0 0;
    font-size: ${({ theme }) => theme.typography.font.size.large};
    font-weight: 700;
    font-family: ${({ theme }) => theme.typography.font.family.yummoVerdana};
    line-height: 2.625rem;
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        letter-spacing: -1px;
        margin: 1.5rem 0 1rem;
        font-size: 2.25rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 2.75rem 0 1.5rem;
    }
`
export const ShowMoreText = styled.div`
    font-size: ${({ theme }) => theme.typography.font.size.small};
    margin: 0.75rem auto 0;
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    font-weight: 700;
    cursor: pointer;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.tfwBlue12};
`
