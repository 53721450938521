import React, { FC, memo, useCallback, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { SolidButton } from 'react-components'

import {
    ButtonText,
    CheckBox,
    CheckBoxLabel,
    CheckBoxLink,
    CheckBoxText,
    CheckboxWrapper,
    ExtraText,
    InformationWrapper,
    InputCheckbox,
    InputEmail,
    InputWrapper,
    ReadMore,
    SubmitEmail,
} from './news-letter-style'
import { verifyEmail } from './utils'
import { CallbackFunction } from '../../common/types'
import { useTranslation } from '../../hooks/locale'
import AnalyticsService from '../../services/analytics-service'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { landingPageEventActions } from '../../services/analytics-service/actions'
import { landingPageEventLabels } from '../../services/analytics-service/labels'
import { fastlaneClient } from '../../clients'
import ButtonTransport from '../../transports/common/button-transport'

interface NewsLetterInputProps {
    setAlertVisible: CallbackFunction
    placeholder: string
    subscribeButtonText: string
    policyDescription: string
    collapsiblePolicyText: string
    policyLinkButton: ButtonTransport
    readMoreLabel: string
    readLessLabel: string
    url: string
}

const NewsletterInput: FC<NewsLetterInputProps> = ({
    placeholder,
    subscribeButtonText,
    policyDescription,
    collapsiblePolicyText,
    policyLinkButton,
    setAlertVisible,
    readMoreLabel,
    readLessLabel,
    url,
}) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const [input, setInput] = useState('')
    const [clicked, setClicked] = useState(false)
    const [check, setCheck] = useState(false)
    const [readMore, setReadMore] = useState(false)
    const linkName = readMore ? readLessLabel : readMoreLabel

    const handleButtonClick = useCallback(async () => {
        if (!input || !check || !verifyEmail(input)) {
            return
        }

        const eventCategory = `${landingPageEventCategories.newsLetterSection.REGISTER} - ${url}`
        const eventAction = landingPageEventActions.newsLetterSection.REGISTER
        const eventLabel = landingPageEventLabels.newsLetterSection.REGISTER
        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: eventLabel })
        try {
            await fastlaneClient.subscribeToNewsLetter({ email: input })
            setAlertVisible(true)
            setInput('')
            setClicked(false)
            setCheck(false)
        } catch {}
    }, [input, check])

    const handleChange = useCallback(
        e => {
            if (e.target.type === 'checkbox') {
                setCheck(!check)
                return
            }
            setInput(e.target.value)
            setClicked(true)
        },
        [check, input],
    )

    const handleInputClick = useCallback(
        e => {
            if (!e.target.value) {
                setClicked(true)
            }
        },
        [clicked],
    )

    const handleInputBlur = useCallback(
        e => {
            if (e.target.value) {
                setClicked(true)
            } else {
                setClicked(false)
            }
        },
        [clicked],
    )

    return (
        <React.Fragment>
            <InputWrapper>
                <InputEmail
                    type={'email'}
                    onChange={handleChange}
                    value={input}
                    name={'email'}
                    onClick={handleInputClick}
                    onBlur={handleInputBlur}
                    required
                    title="E-mail"
                    id="email"
                    placeholder={placeholder}
                />
                <SubmitEmail>
                    <SolidButton
                        onClick={handleButtonClick}
                        padding={`${theme.spacing.xSmall} calc(${theme.spacing.xLarge}
                            + ${theme.spacing.xxSmall})`}
                        width="8.125rem"
                        hoverEffect
                        borderRadius={`0 ${theme.typography.border.radius.small}
                            ${theme.typography.border.radius.small} 0`}
                    >
                        <ButtonText> {subscribeButtonText}</ButtonText>
                    </SolidButton>
                </SubmitEmail>
            </InputWrapper>
            <InformationWrapper>
                <CheckBox>
                    <InputCheckbox
                        type="checkbox"
                        checked={check}
                        name="checkbox"
                        onChange={handleChange}
                        required
                        id="check"
                    />
                    <CheckBoxLabel htmlFor="check" />
                </CheckBox>
                <CheckboxWrapper>
                    <CheckBoxText>
                        {policyDescription}
                        <a
                            className="read-more-link"
                            onClick={() => {
                                setReadMore(!readMore)
                            }}
                        >
                            <ReadMore>{linkName}</ReadMore>
                        </a>
                        {readMore && (
                            <ExtraText>
                                {collapsiblePolicyText}
                                <CheckBoxLink href={policyLinkButton.url} title={policyLinkButton.text}>
                                    {policyLinkButton.text}
                                </CheckBoxLink>
                            </ExtraText>
                        )}
                    </CheckBoxText>
                </CheckboxWrapper>
            </InformationWrapper>
        </React.Fragment>
    )
}

export default memo(NewsletterInput)
