import React, { FC, memo, useCallback, useMemo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import {
    BreadCrumbIcon,
    BreadCrumbLink,
    BreadCrumbListItem,
    BreadCrumbsContainer,
    BreadCrumbsList,
    BreadCrumbsListMobile,
} from './breadcrumb-section-style'
import { getBreadcrumbSchemaDetail } from '../../utils/miscellaneous-utils'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { landingPageEventActions } from '../../services/analytics-service/actions'
import AnalyticsService from '../../services/analytics-service'
import BreadcrumbTransport from '../../transports/common/breadcrumb-transport'

import VectorIcon from '../../assets/icons/vector.svg'

interface Props {
    breadcrumbs: BreadcrumbTransport[]
    url: string
}

const BreadcrumbSection: FC<Props> = ({ breadcrumbs, url }) => {
    const breadcrumbSchemaHtml = useMemo(() => getBreadcrumbSchemaDetail(breadcrumbs), [breadcrumbs])
    const breadCrumbClickHandler = useCallback(
        item => {
            const eventCategory = `${landingPageEventCategories.searchSection.BREADCRUMB} - ${url}`
            const eventAction = '' + landingPageEventActions.searchSection.BREADCRUMB
            AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: String(item.url) })
        },
        [url],
    )

    return (
        <BreadCrumbsContainer>
            <script type="application/ld+json" dangerouslySetInnerHTML={breadcrumbSchemaHtml} />
            <BreadCrumbsListMobile>
                {breadcrumbs.map((item, index) =>
                    index === 0 && breadcrumbs.length > 3 ? (
                        '. . .'
                    ) : index >= breadcrumbs.length - 3 ? (
                        <BreadCrumbListItem key={`${url}_${index}`} onClick={() => breadCrumbClickHandler(item)}>
                            {index !== 0 && <BreadCrumbIcon as={VectorIcon as AnyStyledComponent} />}
                            <BreadCrumbLink title={item.title} href={item.url}>
                                {item.title}
                            </BreadCrumbLink>
                        </BreadCrumbListItem>
                    ) : (
                        ''
                    ),
                )}
            </BreadCrumbsListMobile>
            <BreadCrumbsList>
                {breadcrumbs.map((item, index) => (
                    <BreadCrumbListItem key={`${url}_${index}`} onClick={() => breadCrumbClickHandler(item)}>
                        {index !== 0 && <BreadCrumbIcon as={VectorIcon as AnyStyledComponent} />}
                        <BreadCrumbLink title={item.title} href={item.url}>
                            {item.title}
                        </BreadCrumbLink>
                    </BreadCrumbListItem>
                ))}
            </BreadCrumbsList>
        </BreadCrumbsContainer>
    )
}

export default memo(BreadcrumbSection)
