import React, { FC, memo, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import Image from 'next/image'

import LoaderGrey from '../../assets/gifs/loader-grey.gif'

interface Props {
    src: string
    alt: string
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
    priority?: boolean
    quality?: number | string
    showPlaceholder?: boolean
}

const NextImage: FC<Props> = ({ src, alt, objectFit, priority = false, quality = 75, showPlaceholder = false }) => {
    const [hasPlaceholder, setHasPlaceholder] = useState(true)
    return (
        <Container>
            {showPlaceholder && hasPlaceholder && (
                <Image src={LoaderGrey} alt="placeholder" layout="fill" objectFit="contain" />
            )}

            <Image
                src={src}
                alt={alt}
                layout="fill"
                objectFit={!!objectFit ? objectFit : 'cover'}
                onLoadingComplete={() => (showPlaceholder ? setHasPlaceholder(false) : null)}
                priority={priority}
                quality={quality}
            />
        </Container>
    )
}

const Container = styled.div(
    ({ theme }) => css`
        position: relative;
        overflow: hidden;
        height: ${theme.layout.fill};
        width: ${theme.layout.fill};
    `,
)

export default memo(NextImage)
