import React, { FC, memo, useContext, useEffect, useState } from 'react'
import { ScreenContext } from 'react-components'
import { AnyStyledComponent } from 'styled-components'

import {
    CarouselContainer,
    Container,
    Star,
    StarSmall,
    StyledButton,
    StyledCarouselProvider,
    StyledSlider,
    SubTitle,
    Title,
    TitleBubble,
    TitleContainer,
} from './styles'
import { useTranslation } from '../../../hooks/locale'
import ListingSectionTransport from '../../../transports/common/listing-section-transport'
import { useTopRatedListingsSection } from '../../../hooks/listings'
import StarGreen from '../../../assets/icons/star-green.svg'
import CarouselButtonNext from './carousel-button-next'
import CarouselButtonBack from './carousel-button-back'
import ReviewCards from './cards'
import { LoadingIcon } from '../top-offers-styles'

interface Props {
    topRatedObjectsSection: ListingSectionTransport
}

const getSlides = (isMobile: boolean, isDesktop: boolean, isTablet: boolean, isWideScreen: boolean) => {
    if (isMobile) {
        return 1
    } else if (isTablet) {
        return 1.5
    } else if (isDesktop && !isWideScreen) {
        return 3
    } else {
        return 3
    }
}

const getSlidesToScroll = (isMobile: boolean, isDesktop: boolean, isTablet: boolean, isWideScreen: boolean) => {
    if (isMobile) {
        return 1
    } else if (isTablet) {
        return 1
    } else if (isDesktop && !isWideScreen) {
        return 3
    } else {
        return 3
    }
}

const ReviewsSection: FC<Props> = ({ topRatedObjectsSection }) => {
    const { section, getTopRatedListings, isLoading } = useTopRatedListingsSection(topRatedObjectsSection)
    const { objects: listings, totalCount: finalTotalReviewCount } = section
    const { heading } = topRatedObjectsSection
    const { t } = useTranslation()
    const { isMobile, isDesktop, isTablet, isWideScreen } = useContext(ScreenContext)
    const slides = getSlides(isMobile, isDesktop, isTablet, isWideScreen)
    const slidesToScroll = getSlidesToScroll(isMobile, isDesktop, isTablet, isWideScreen)
    const [stopSliderTransition, setStopSliderTransition] = useState(isLoading)

    useEffect(() => {
        setTimeout(function () {
            setStopSliderTransition(isLoading)
        }, 500)
    }, [isLoading])

    return (
        <Container id="reviews">
            <TitleContainer>
                <Title>{heading}</Title>
                {isDesktop && (
                    <TitleBubble>
                        <Star as={StarGreen as AnyStyledComponent} />
                        <StarSmall as={StarGreen as AnyStyledComponent} />
                        {t('reviewBubbleText')}
                    </TitleBubble>
                )}
            </TitleContainer>
            {!isMobile && <SubTitle>{t('reviewsSubTitle')}</SubTitle>}
            <CarouselContainer>
                <StyledCarouselProvider
                    totalSlides={listings.length}
                    visibleSlides={slides}
                    naturalSlideWidth={1}
                    naturalSlideHeight={1}
                    isIntrinsicHeight
                    lockOnWindowScroll
                    dragStep={1}
                    step={slidesToScroll}
                    dragEnabled={!isDesktop}
                >
                    <CarouselButtonBack />
                    <StyledSlider $stopSliderTransition={stopSliderTransition}>
                        <ReviewCards
                            listings={listings}
                            loadMoreListings={getTopRatedListings}
                            visibleSlides={slides}
                            isLoading={stopSliderTransition}
                            finalTotalReviewCount={finalTotalReviewCount}
                        />
                    </StyledSlider>
                    <CarouselButtonNext
                        numberOfSlides={listings.length}
                        visibleSlides={slides}
                        isLoading={stopSliderTransition}
                    />
                </StyledCarouselProvider>
                {stopSliderTransition && (
                    <StyledButton $right>
                        <LoadingIcon />
                    </StyledButton>
                )}
            </CarouselContainer>
        </Container>
    )
}

export default memo(ReviewsSection)
