import React, { FC } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { AnchorStyles, Box, RegionName, RightIcon, SpanStyles } from './link-box-styles'
import ExternalLink from '../common/external-link'
import { AccommodationItemTransport } from '../../transports/region-landing-page-transport'

import ChevronRightDefault from '../../assets/icons/chevron-right-default.svg'

interface Props {
    item: AccommodationItemTransport
    hidden?: boolean
    locationName?: string
}

const LinkBox: FC<Props> = ({ item, hidden = false }) => {
    const { link, title, linkTitle, noCrawl } = item
    return (
        <ExternalLink as={AnchorStyles} title={linkTitle} href={link} noCrawl={noCrawl}>
            <Box hidden={hidden}>
                <SpanStyles>
                    <RegionName>{title}</RegionName>
                </SpanStyles>
                <RightIcon as={ChevronRightDefault as AnyStyledComponent} />
            </Box>
        </ExternalLink>
    )
}

export default LinkBox
