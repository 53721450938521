import React, { FC, memo, useCallback, useContext } from 'react'
import { Card, ScreenContext, SolidButton } from 'react-components'
import { ThemeContext } from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { TipTransport } from '../../transports/region-landing-page-transport'
import {
    ButtonContainer,
    CardContainer,
    ContentContainer,
    ImageContainer,
    LogoContainer,
    LogoImage,
    LogoText,
    MarkdownOuterContainer,
    MarkdownTextContainer,
    ShowMoreText,
    Subtitle,
    Title,
} from './tips-section-styles'
import { CallbackFunction } from '../../common/types'
import ImageLoadingComponent from '../app-components/image-loading-component'
import { useTranslation } from '../../hooks/locale'

type Props = {
    tip: TipTransport
    toggleOverlay: CallbackFunction
    location: string
}

const TipsSectionCard: FC<Props> = ({ tip, toggleOverlay, location }) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const { isMobile } = useContext(ScreenContext)

    const _handleReadMoreClick = useCallback(() => toggleOverlay(tip), [toggleOverlay, tip])

    return (
        <CardContainer>
            <Card borderRadius={'12px'}>
                <LogoContainer>
                    <LogoImage />
                    <LogoText>{t('imageIconText')}</LogoText>
                </LogoContainer>
                <ImageContainer>
                    <ImageLoadingComponent
                        src={
                            (isMobile && tip.image?.formats?.small?.url) ||
                            tip.image?.formats?.large?.url ||
                            tip.image?.url
                        }
                        alt={'tips-image'}
                        objectFit={'cover'}
                        borderRadius={`${theme.typography.border.radius.xLarge}
                            ${theme.typography.border.radius.xLarge} 0 0`}
                    />
                </ImageContainer>
                <ContentContainer>
                    <Title>{tip.title}</Title>
                    <Subtitle>{tip.subtitle}</Subtitle>
                    <MarkdownOuterContainer>
                        <MarkdownTextContainer>
                            <ReactMarkdown>{tip.text}</ReactMarkdown>
                        </MarkdownTextContainer>
                    </MarkdownOuterContainer>
                    {isMobile && <ShowMoreText onClick={_handleReadMoreClick}>{t('showMoreText')}</ShowMoreText>}
                    <ButtonContainer>
                        <SolidButton
                            href={tip.cta?.url}
                            padding={`${theme.spacing.xSmall} ${theme.spacing.xLarge}`}
                            width={theme.layout.fill}
                        >
                            {`${t('tipsSectionButtonText')} ${location}`}
                        </SolidButton>
                    </ButtonContainer>
                </ContentContainer>
            </Card>
        </CardContainer>
    )
}

export default memo(TipsSectionCard)
