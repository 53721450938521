import React, { FC, memo, useCallback, useContext, useState, useEffect } from 'react'
import { ScreenContext } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import { CtaButtonText, CtaContainer, MainContainer, StickyCtaButton } from './styles'
import AnalyticsService from '../../../services/analytics-service'
import { landingPageEventCategories } from '../../../services/analytics-service/categories'
import { landingPageEventActions } from '../../../services/analytics-service/actions'
import { CookieManagerService } from '../../../services'
import { STICKY_FOOTER_SCROLL_THRESHOLD } from '../constants'

interface StickyFooterProps {
    url?: string
}

const StickeyFooter: FC<StickyFooterProps> = ({ url }) => {
    const { t } = useTranslation()
    const { isMobile } = useContext(ScreenContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleClick = useCallback(() => {
        setLoading(true)
        const searchParams = CookieManagerService.getCookie(CookieManagerService.SEARCH_PARAMS)
        const baseUrl = new URL(`${t('traumBaseUrl')}${url}`)
        let finalUrl = baseUrl.toString()
        if (searchParams) {
            const [arrivalDate, departureDate] = searchParams.split('|')
            const hasBothDates = arrivalDate && departureDate
            if (hasBothDates) {
                baseUrl.searchParams.append('arrival', arrivalDate)
                baseUrl.searchParams.append('departure', departureDate)
                finalUrl = baseUrl.toString()
            }
        }
        AnalyticsService.trackEvent({
            action: landingPageEventActions.stickyFooter.VIEW_ALL_RECOMMENDATION_CLICKED,
            category: landingPageEventCategories.stickyFooterSection.STICKY_FOOTER,
            label: finalUrl,
        })
        window.location.href = finalUrl
    }, [url, t])

    const handleScroll = useCallback(() => {
        if (window.scrollY > STICKY_FOOTER_SCROLL_THRESHOLD) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    if (!url || !isMobile) {
        return null
    }

    return (
        <MainContainer id="sticky-footer" visible={visible}>
            <CtaContainer>
                <StickyCtaButton onClick={handleClick} hoverEffect isLoading={loading}>
                    <CtaButtonText>{`${t('ctaButtonTextTopRecommendation')}`}</CtaButtonText>
                </StickyCtaButton>
            </CtaContainer>
        </MainContainer>
    )
}

export default memo(StickeyFooter)
