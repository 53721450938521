import styled, { css } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint } from 'react-components'

import { getBasePath } from '../../utils/miscellaneous-utils'

export const OuterSection = styled.section`
    max-width: 87.5rem;
    margin: ${props => props.theme.spacing.large} ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${props => props.theme.spacing.xxLarge} auto;
        padding: 0 ${props => props.theme.spacing.large};
    }
`

export const InnerContainer = styled.section`
    position: relative;
    height: 22rem;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 24.5rem;
    }
    img {
        border-radius: ${props => props.theme.typography.border.radius.xLarge};
    }
`

export const InnerSection = styled.section`
    color: ${props => props.theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    border-top: ${props => props.theme.typography.border.radius.xxxSmall}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlack12};
    background: linear-gradient(90deg, #1f397c 0%, #3261b700 134.38%);
    padding: ${props => props.theme.spacing.xxLarge} ${props => props.theme.spacing.large};
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: calc(${props => props.theme.spacing.xxxLarge} + ${props => props.theme.spacing.medium})
            calc(${props => props.theme.spacing.xxxLarge} * 3.25)
            calc(${props => props.theme.spacing.xxxLarge} + ${props => props.theme.spacing.medium})
            calc(${props => props.theme.spacing.xxxLarge} + ${props => props.theme.spacing.medium});
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`

export const NewsLetterAnchor = styled.div`
    position: ${({ theme }) => theme.style.position.absolute};
    top: -8rem;
    visibility: hidden;
`

export const NewsLetterWrapper = styled.div`
    max-width: 38.75rem;
    margin: 0 auto;
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 4.75rem 0;
        max-width: 32.125rem;
        margin: 0 13.5rem;
    }
`

export const NewsLetterHeading = styled.span(
    ({ theme }) => css`
        font-family: ${theme.typography.font.family.yummo};
        color: ${theme.colors.white};
        font-weight: ${theme.typography.font.yummo.weight.bold};
        margin: 0;
        text-align: ${theme.style.textAlign.left};
        font-size: ${theme.typography.font.size.large};
        line-height: 1.75rem;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: 2.25rem;
            line-height: 2.625rem;
            letter-spacing: -1px;
        }
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            font-family: ${theme.typography.font.family.yummo};
            text-align: ${theme.style.textAlign.left};
            line-height: 2.625rem;
            letter-spacing: -1px;
        }
    `,
)

export const NewsLetterSubheading = styled.span`
    font-size: ${props => props.theme.typography.font.size.small};
    font-weight: 400;
    text-align: ${props => props.theme.style.textAlign.left};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    line-height: 1.25rem;
    margin-bottom: ${props => props.theme.spacing.large};
    margin-top: 0;
    color: #ffffff80;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 0 1.25rem 0;
        text-align: ${props => props.theme.style.textAlign.left};
        font-family: ${props => props.theme.typography.font.family.lato};
        font-weight: ${props => props.theme.typography.font.weight.normal};
        font-size: ${props => props.theme.typography.font.size.medium};
        line-height: 1.5rem;
        color: ${props => props.theme.colors.white};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-family: ${props => props.theme.typography.font.family.lato};
        font-weight: ${props => props.theme.typography.font.weight.normal};
        text-align: ${props => props.theme.style.textAlign.left};
        font-size: ${props => props.theme.typography.font.size.medium};
        line-height: 1.5rem;
        margin-bottom: ${props => props.theme.spacing.large};
    }
`

export const InputWrapper = styled.div`
    position: ${props => props.theme.style.position.relative};
    color: ${props => props.theme.colors.tfwBlack12};
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: calc(${props => props.theme.layout.xSmall} + ${props => props.theme.layout.xxSmall});
    }
`

export const InputEmail = styled.input`
    border-radius: ${props => props.theme.typography.border.radius.small} 0 0
        ${props => props.theme.typography.border.radius.small};
    min-height: calc(${props => props.theme.layout.xxSmall} + ${props => props.theme.layout.xSmall});
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    font-weight: 400;
    background-color: ${props => props.theme.colors.white};
    border: 0;
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    letter-spacing: 0.2px;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    outline: 0;
    width: ${props => props.theme.layout.fill};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0.5625rem ${props => props.theme.spacing.medium};
    }
`

export const InputLabel = styled.label<{ clicked: boolean }>`
    white-space: nowrap;
    z-index: 1;
    position: ${props => props.theme.style.position.absolute};
    display: ${props => props.theme.style.display.block};
    pointer-events: none;
    top: 0;
    padding-left: calc(${props => props.theme.spacing.xSmall} + ${props => props.theme.spacing.xxxSmall});
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    font-weight: 400;
    color: ${props => props.theme.colors.tfwBlack45};
    transition: padding-top 200ms, font-size 200ms;
    cursor: pointer;
    width: ${props => props.theme.layout.fill};
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: ${props => (props.clicked ? '0.625rem' : '1.25rem')};
    font-size: ${props => (props.clicked ? '0.75rem' : '0.875rem')};
`

export const InformationWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    padding-top: calc(${props => props.theme.spacing.xxSmall} + ${props => props.theme.spacing.medium});
`

export const CheckboxWrapper = styled.label`
    align-items: ${props => props.theme.style.alignItems.flexStart};
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.row};
    cursor: default;
    font-size: ${props => props.theme.typography.font.size.small};
    font-weight: 400;
    color: ${props => props.theme.colors.white};
`

export const CheckBox = styled.div``

export const CheckBoxText = styled.span`
    flex: 1;
    cursor: pointer;
    font-size: ${props => props.theme.typography.font.size.xSmall};
    font-weight: 400;
    color: #ffffff80;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    line-height: 1rem;
    letter-spacing: 0.2px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        color: ${props => props.theme.colors.white};
        font-size: ${props => props.theme.typography.font.size.small};
    }
`

export const ReadMore = styled.p`
    color: ${props => props.theme.colors.white};
`

export const ExtraText = styled.p`
    margin: 0;
    color: ${props => props.theme.colors.white};
`

export const CheckBoxLink = styled.a`
    color: #ffffff80;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-weight: 400;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        color: ${props => props.theme.colors.white};
    }
`

export const SubmitEmail = styled.div`
    color: ${props => props.theme.colors.tfwBlack87};
    font-size: ${props => props.theme.typography.font.size.small};
    text-align: ${props => props.theme.style.textAlign.center};
    font-weight: 400;
    align-items: ${props => props.theme.style.alignItems.center};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.small};
    }
`

export const ButtonText = styled.div`
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    text-align: ${props => props.theme.style.textAlign.center};
    color: ${props => props.theme.colors.tfwBlack87};
    line-height: 1.5rem;
`
export const CheckBoxLabel = styled.label`
    margin-right: calc(${props => props.theme.spacing.xSmall} + ${props => props.theme.spacing.xxxSmall});
    padding: 0.5625rem;
    border-radius: ${props => props.theme.typography.border.radius.xxSmall};
    display: ${props => props.theme.style.display.inlineBlock};
    position: ${props => props.theme.style.position.relative};
    cursor: pointer;
    font-size: ${props => props.theme.layout.fill};
    outline: 0;
    background-color: transparent;
    border: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.white};
`

const checkBoxUrl = `url(${getBasePath('/assets/icons/check.svg')})`

export const InputCheckbox = styled.input`
    &:checked + ${CheckBoxLabel} {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 1.125rem;
        height: 1.125rem;
        background: ${props => props.theme.colors.white} ${checkBoxUrl} no-repeat center;
        background-size: cover;
    }

    display: none;
`
