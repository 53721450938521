import styled from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

export const Section = styled.section`
    padding: 0 1rem;
    width: 100%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: flex;
        flex-wrap: wrap;
        padding: 0 1.5rem;
    }
`
export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    overflow: auto;
`
export const TextWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-wrap: ${props => props.theme.style.flexWrap.wrap};
    flex-basis: ${props => props.theme.layout.fill};
    margin: 0 auto;
`

export const Text = styled.h2`
    margin: 2.5rem 0 1rem;
    font-size: ${({ theme }) => theme.typography.font.size.large};
    font-weight: 700;
    font-family: ${({ theme }) => theme.typography.font.family.yummoVerdana};
    line-height: 1.75rem;
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        line-height: 2.625rem;
        font-size: 2.25rem;
        letter-spacing: -1px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 0 1.5rem;
    }
`

export const MenuWrapper = styled.div`
    border: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
    min-width: 9.75rem;
    margin-right: ${props => props.theme.spacing.xSmall};
    padding: calc(${props => props.theme.spacing.xxxSmall} + ${props => props.theme.spacing.xSmall}) 0
        ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        min-width: 10.5rem;
        margin-right: ${props => props.theme.spacing.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        min-width: 12.05rem;
        margin-right: ${props => props.theme.spacing.large};
        padding-bottom: ${props => props.theme.spacing.large};
    }
`

export const MenuContainer = styled.a`
    text-align: ${props => props.theme.style.textAlign.left};
    text-decoration: none;
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    align-items: ${props => props.theme.style.alignItems.baseline};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    font-weight: 400;
    color: ${props => props.theme.colors.tfwBlack87};
    cursor: pointer;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        text-align: ${props => props.theme.style.textAlign.left};
        display: ${props => props.theme.style.display.flex};
        flex-direction: ${props => props.theme.style.flexDirection.column};
        align-items: ${props => props.theme.style.alignItems.baseline};
    }
`

export const IconBox = styled.div`
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 9.375rem 9.375rem;
    margin-left: calc(${props => props.theme.spacing.xxxSmall} + ${props => props.theme.spacing.xSmall});
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-left: calc(${props => props.theme.spacing.small} + ${props => props.theme.spacing.xxxSmall});
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: calc(${props => props.theme.spacing.xSmall} + ${props => props.theme.spacing.xxxSmall});
        margin-bottom: 0;
    }
`

export const Image = styled.img`
    width: ${props => props.theme.layout.small};
    height: ${props => props.theme.layout.small};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: ${props => props.theme.layout.large};
        height: ${props => props.theme.layout.large};
    }
`
export const TitleSpan = styled.h3`
    width: ${props => props.theme.layout.fill};
    white-space: nowrap;
    display: ${props => props.theme.style.display.block};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: ${props => props.theme.typography.font.size.small};
    font-family: ${props => props.theme.typography.font.family.lato};
    line-height: 1.5rem;
    margin-left: ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0;
        font-size: ${props => props.theme.typography.font.size.medium};
        margin-left: ${props => props.theme.spacing.large};
    }
`

export const SubtitleSpan = styled.span`
    font-family: ${props => props.theme.typography.font.family.lato};
    display: ${props => props.theme.style.display.block};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    letter-spacing: 0.0125rem;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    margin-left: ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.small};
        margin-left: ${props => props.theme.spacing.large};
        line-height: 1.5rem;
    }
`
