import styled, { AnyStyledComponent } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

import { LocationPinIcon } from '../../common/icons'
import ExternalLink from '../../common/external-link'

export const Container = styled.div`
    margin: 0 0.5rem 0 0;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.typography.border.radius.xLarge};
    border: 1px solid ${props => props.theme.colors.tfwBlackOpacity10};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 1rem 0 0;
        min-height: 24.1875rem;
        max-height: 30rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0 1.5rem 0 0;
    }
`
export const InnerContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    position: ${props => props.theme.style.position.relative};
    transition: box-shadow 200ms ease-out;
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
    padding: 0.75rem 0.75rem 0.25rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 1rem;
    }
`

export const TopContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.colors.tfwBlackOpacity10};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-bottom: 1rem;
    }
`

export const ImageContainer = styled.div`
    height: 3.75rem !important;
    width: 5.625rem !important;
    max-width: 32%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 5rem !important;
        width: 7.5rem !important;
    }

    img {
        border-radius: ${props => props.theme.typography.border.radius.small};
    }
`

export const ApartmentInfoContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    margin-left: 0.5rem;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    flex: 1;
    width: 66%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-left: 1rem;
        justify-content: flex-start;
    }
`

export const ApartmentTitle = styled.h3`
    /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
    font-weight: 900;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0.25rem 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.large};
        line-height: 1.75rem;
        margin: 0;
    }
`

export const SeoHref = styled(ExternalLink)`
    font: inherit;
    text-decoration: none;
`

export const ApartmentFooter = styled.div`
    display: ${props => props.theme.style.display.flex};
    line-height: 1.5rem;
    margin-top: 0.25rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 0;
    }
`

export const IconContainer = styled.div`
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 1.5rem;
    }
`

export const Pin = styled(LocationPinIcon as AnyStyledComponent)`
    width: 1rem;
    height: 1rem;
`

export const ApartmentRegion = styled.span`
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    margin-left: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const VerticalSeparator = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 0.0625rem;
    height: 1rem;
    margin: 0 0.5rem;
    background: ${props => props.theme.colors.tfwBlackOpacity10};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0.25rem 0.5rem 0.25rem;
    }
`

export const StarIcon = styled.img`
    width: 1rem;
    height: 1rem;
`

export const ApartmentRating = styled.div`
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    margin-left: 0.25rem;
    white-space: nowrap;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

export const ReviewContainer = styled.div`
    /* stylelint-disable */
    display: ${props => props.theme.style.display.flex};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: justify;
    margin: 0.5rem 0 0.75rem;
    min-height: 11rem;
    max-height: 11rem;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        margin: 0.75rem 0;
        line-height: 1.5rem;
        min-height: 11.9375rem;
        max-height: 11.9375rem;
        -webkit-line-clamp: 8;
    }
`

export const ReviewText = styled.p`
    margin: 0;
    display: inline;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
`

export const FooterContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    min-height: 2.75rem;
    max-height: 2.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
`
export const ReviewerContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    display: ${props => props.theme.style.display.flex};
    width: 40%;
    white-space: nowrap;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: auto;
    }
`

export const UserContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: ${props => props.theme.colors.tfwWhite64};
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 2rem;
        height: 2rem;
    }
`
export const User = styled.img`
    width: 1rem;
    height: 1rem;
`

export const UserInfo = styled.div`
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    color: ${props => props.theme.colors.tfwBlack87};
    margin-left: 0.75rem;
    padding: 0.25rem 0;
    white-space: nowrap;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        line-height: 1.5rem;
    }
`

export const PriceContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    display: ${props => props.theme.style.display.flex};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    white-space: nowrap;
    padding: 0.25rem 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        line-height: 1.5rem;
    }
`

export const PriceText = styled.div`
    display: ${props => props.theme.style.display.flex};
    font-weight: ${props => props.theme.typography.font.lato.weight.bold};
`

export const NightText = styled.div`
    display: ${props => props.theme.style.display.flex};
    font-weight: 400;
    margin-left: ${props => props.theme.spacing.xxSmall};
`
