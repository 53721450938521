import React, { FC, memo, useCallback, useMemo } from 'react'
import { Card } from 'react-components'
import { AnyStyledComponent } from 'styled-components'

import {
    ApartmentFooter,
    ApartmentInfoContainer,
    ApartmentRating,
    ApartmentRegion,
    ApartmentTitle,
    Container,
    FooterContainer,
    IconContainer,
    ImageContainer,
    InnerContainer,
    Pin,
    NightText,
    PriceContainer,
    PriceText,
    ReviewContainer,
    ReviewerContainer,
    ReviewText,
    StarIcon,
    TopContainer,
    User,
    UserContainer,
    UserInfo,
    VerticalSeparator,
    SeoHref,
} from './reviews-card-styles'
import { useTranslation } from '../../../hooks/locale'
import { ListingSectionObject } from '../../../transports/common/listing-section-transport'
import Star from '../../../assets/icons/star.svg'
import UserReviewIcon from '../../../assets/icons/user-review.svg'
import NextImage from '../../common/next-image'
import { usePropertyCardTracking } from '../../landing-page/hooks'
import { preventDefaultHandler } from '../../utils'

interface Props {
    listing: ListingSectionObject
    position: number
}

const ReviewsCard: FC<Props> = ({ listing, position }) => {
    const { href, apartment, seoRegion, rating, price, breadcrumbs, seoHref } = listing

    const { average, count, review } = rating

    const reviewerName = review?.latestRating?.contact.firstName ?? review?.latestRating?.contact.lastName
    const reviewerCity = review?.latestRating?.contact.address.city

    const { t } = useTranslation()

    const { intersectionTargetRef, propertyCardClickTracking } = usePropertyCardTracking(listing, 'TOP_RATED', position)

    const handleCardClick = useCallback(() => {
        propertyCardClickTracking(listing, 'TOP_RATED')

        window.open(`${t('traumBaseUrl')}${href}`, '_blank')
    }, [href, listing, propertyCardClickTracking, t])

    const handleRegionClick = useCallback((event: Event) => {
        event.stopPropagation()
    }, [])

    return (
        <Container onClick={handleCardClick} ref={intersectionTargetRef}>
            <Card borderRadius={'0.75rem'}>
                <InnerContainer>
                    <TopContainer>
                        {!!apartment && apartment.images.length > 0 && (
                            <ImageContainer>
                                <NextImage src={apartment.images[0].url} alt="apartment" showPlaceholder />
                            </ImageContainer>
                        )}
                        <ApartmentInfoContainer>
                            {!!apartment && (
                                <ApartmentTitle>
                                    <SeoHref
                                        autoRedirect={false}
                                        title={apartment.title}
                                        href={seoHref}
                                        onClick={preventDefaultHandler}
                                    >
                                        {apartment.title}
                                    </SeoHref>
                                </ApartmentTitle>
                            )}
                            <ApartmentFooter>
                                <IconContainer>
                                    <Pin />
                                </IconContainer>
                                <ApartmentRegion>
                                    {breadcrumbs ? (
                                        <SeoHref
                                            title={breadcrumbs[breadcrumbs.length - 1].name}
                                            href={breadcrumbs[breadcrumbs.length - 1].url}
                                            onClick={handleRegionClick}
                                        >
                                            {breadcrumbs[breadcrumbs.length - 1].name}
                                        </SeoHref>
                                    ) : (
                                        <SeoHref
                                            title={seoRegion.name}
                                            href={seoRegion.url.url}
                                            noCrawl={seoRegion.url.noCrawl}
                                            onClick={handleRegionClick}
                                        >
                                            {seoRegion.name}
                                        </SeoHref>
                                    )}
                                </ApartmentRegion>
                                <VerticalSeparator />
                                <IconContainer>
                                    <StarIcon as={Star as AnyStyledComponent} />
                                </IconContainer>
                                <ApartmentRating>{`${average}/5 (${count} ${
                                    count === 1 ? t('review') : t('reviews')
                                })`}</ApartmentRating>
                            </ApartmentFooter>
                        </ApartmentInfoContainer>
                    </TopContainer>

                    <ReviewContainer>
                        {/*<ReviewGreeting>{t('reviewsGreeting')}</ReviewGreeting>*/}
                        {review?.latestRating && <ReviewText>&quot;{review.latestRating.text}&quot;</ReviewText>}
                    </ReviewContainer>

                    <FooterContainer>
                        <ReviewerContainer>
                            <UserContainer>
                                <User as={UserReviewIcon as AnyStyledComponent} />
                            </UserContainer>
                            <UserInfo>
                                {!!reviewerName && `${reviewerName}, `}
                                {!!reviewerCity && reviewerCity}
                            </UserInfo>
                        </ReviewerContainer>
                        <PriceContainer>
                            <PriceText>{price.price}</PriceText>
                            {!!price.unit && <NightText> / {price.unit}</NightText>}
                        </PriceContainer>
                    </FooterContainer>
                </InnerContainer>
            </Card>
        </Container>
    )
}

export default memo(ReviewsCard)
