import React, { FC } from 'react'

import { StatsContainer, RegionTextItem, RegionTextSeparator } from './vertical-property-card-styles'
import { useTranslation } from '../../../../hooks/locale'
import ListingStatsTransport from '../../../../transports/common/listing-stats-transport'

interface Props {
    stats: ListingStatsTransport<number>
}

const PropertyStatsContainer: FC<Props> = ({ stats }) => {
    const { t } = useTranslation()
    const bedroomsStat = !!stats?.bedrooms
        ? stats.bedrooms
        : !!stats?.properBedrooms
        ? `${stats.properBedrooms} ${t('bedrooms')}`
        : ''

    // TODO: bathrooms stats are not coming from backend in the response for object-by-popular-filter API, get that from
    // backend and then apply all 4 stats in the container with the 4th one hidden when viewports becomes < 476px
    // const bathroomsStat = !!stats?.bathrooms
    const maxPersonStat = !!stats?.personCount
        ? stats.personCount
        : !!stats?.maxPersons
        ? `${t('max')} ${stats.maxPersons} ${stats.maxPersons === 1 ? t('guest') : t('guests')}`
        : ''

    const propertySize = !!stats?.qmSize ? stats.qmSize : `${stats.apartmentSize || 0} ${t('meterSquare')}`
    return (
        <StatsContainer>
            <RegionTextItem>{bedroomsStat}</RegionTextItem>
            {bedroomsStat && <RegionTextSeparator>&#8226;</RegionTextSeparator>}
            <RegionTextItem>{maxPersonStat}</RegionTextItem>
            {maxPersonStat && <RegionTextSeparator>&#8226;</RegionTextSeparator>}
            <RegionTextItem>{propertySize}</RegionTextItem>
        </StatsContainer>
    )
}

export default PropertyStatsContainer
