import React, { FC, memo } from 'react'

import { PopularFiltersSectionTransport } from '../../transports/region-landing-page-transport'
import ExternalLink from '../common/external-link'
import { useTranslation } from '../../hooks/locale'
import {
    Container,
    IconBox,
    MenuContainer,
    MenuWrapper,
    Section,
    SubtitleSpan,
    Text,
    TextWrapper,
    TitleSpan,
} from './popular-object-redesign-style'
import FilterIcon from './filter-icon'

interface PopularObjectProps {
    popularFiltersSection: PopularFiltersSectionTransport
}

const PopularObjectRedesign: FC<PopularObjectProps> = ({ popularFiltersSection }) => {
    const { t } = useTranslation()
    const { heading, filters } = popularFiltersSection
    return (
        <Section id="popular-objects">
            <TextWrapper>
                <Text>{heading}</Text>
            </TextWrapper>
            <Container>
                {filters.map(filter => (
                    <MenuWrapper key={filter.resultListUrl}>
                        <ExternalLink
                            title={filter.title}
                            as={MenuContainer}
                            href={`${filter.resultListUrl}`}
                            noCrawl={filter.noCrawl}
                        >
                            <IconBox>
                                <FilterIcon filterKey={filter.title} alt={`${filter.title}-icon`} />
                            </IconBox>
                            <TitleSpan>{filter.title}</TitleSpan>
                            <SubtitleSpan>{`${filter.count} ${
                                filter.count === 1 ? t('accommodation') : t('accommodations')
                            }`}</SubtitleSpan>
                        </ExternalLink>
                    </MenuWrapper>
                ))}
            </Container>
        </Section>
    )
}

export default memo(PopularObjectRedesign)
