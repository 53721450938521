import React, { FC, memo, useContext } from 'react'

import { ScreenContext } from 'react-components'
import { Slide } from 'pure-react-carousel'

import ListingSectionTransport from '../../../transports/common/listing-section-transport'
import ListingSectionVerticalCard from './vertical-card'
import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { useLoadMoreSlides } from '../hooks'
import { LoadingIcon } from '../top-offers-styles'
import { StyledButton } from '../reviews-section/styles'

interface Props {
    listings: ListingSectionTransport['objects']
    isLoading: boolean
    url: string
    listingType?: string
    visibleSlides: number
    loadMoreListings: () => void
    finalTotalListingCount: number
}

const Cards: FC<Props> = ({
    listings,
    isLoading,
    url,
    listingType,
    loadMoreListings,
    visibleSlides,
    finalTotalListingCount,
}) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    useLoadMoreSlides({
        currentSlideIndex: _currentSlideIndex,
        visibleSlides,
        currentTotalSlideCount: listings.length,
        loadMoreSlides: loadMoreListings,
        finalTotalSlideCount: finalTotalListingCount,
    })

    const { isDesktop } = useContext(ScreenContext)

    return (
        <>
            {listings.map((listing, index) => (
                <Slide index={listing.id} key={`${index}_${listing.id}`}>
                    <ListingSectionVerticalCard
                        listingObject={listing}
                        isLoading={isLoading}
                        key={listing.id}
                        url={url}
                        listingType={listingType}
                        position={index}
                    />
                </Slide>
            ))}
            {isLoading && !isDesktop && (
                <StyledButton $right>
                    <LoadingIcon />
                </StyledButton>
            )}
        </>
    )
}

export default memo(Cards)
