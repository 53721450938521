import { NetworkService } from '../services'
import { BaseTransport } from '../transports'
import SuggestionsTransport from '../transports/suggestion-transport'
import { UNSAFE_SearchUrlTransport } from '../transports/search-url-transport'

class FastlaneClient {
    public getSearchbarSuggestions({
        inputText,
        count,
    }: {
        inputText: string
        count: number
    }): Promise<BaseTransport<SuggestionsTransport>> {
        const meta = { resource: 'suggestions' }
        return NetworkService.UNSAFE_post({
            url: '/fl/ajax/regions/autocomplete',
            params: { limit: count },
            body: { query: inputText },
            meta,
        })
    }

    public getSearchUrl({
        search,
        persons,
        arrival,
        departure,
        regionId,
    }: {
        search: string
        persons: number
        arrival: string
        departure: string
        regionId: number
    }): Promise<BaseTransport<UNSAFE_SearchUrlTransport>> {
        const meta = { resource: 'search' }
        return NetworkService.UNSAFE_post({
            url: '/fl/api/v1/search',
            body: {
                search,
                persons,
                arrival,
                departure,
                region_node_id: regionId || undefined,
            },
            meta,
        })
    }

    public addToWishlist({ regionId }: { regionId: number }): Promise<{ status: string }> {
        const meta = { resource: 'wishlist' }
        return NetworkService.UNSAFE_put({
            url: `/fl/ajax/favorites/${regionId}`,
            meta,
        })
    }

    public removeFromWishlist({ regionId }: { regionId: number }): Promise<{ status: string }> {
        const meta = { resource: 'wishlist' }
        return NetworkService.UNSAFE_delete({
            url: `/fl/ajax/favorites/${regionId}`,
            meta,
        })
    }

    public subscribeToNewsLetter({ email }: { email: string }) {
        const meta = { resource: 'search' }
        return NetworkService.UNSAFE_post({
            url: '/fl/api/v1/newsletter/subscribe',
            body: { email },
            meta,
        })
    }
}

export default new FastlaneClient()
