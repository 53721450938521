import React, { FC, memo } from 'react'

import { RegionsSectionTransport } from '../../transports/region-landing-page-transport'
import VacationRegion from './vacation-region'

import { Container, Title } from './vacation-region-styles'

interface Props {
    regionsSection: RegionsSectionTransport
}

const VacationRegionsSection: FC<Props> = ({ regionsSection: { heading, regions } }) => {
    return (
        <Container id="vacation-regions">
            <Title>{heading}</Title>
            {regions.map(region => (
                <VacationRegion key={region.id} {...region} />
            ))}
        </Container>
    )
}

export default memo(VacationRegionsSection)
