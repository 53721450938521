/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { memo, useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react'
import { ScreenContext } from 'react-components'
import { AnyStyledComponent } from 'styled-components'
import dynamic from 'next/dynamic'

import {
    DateInputContainer,
    IconWrapper,
    LeftContainer,
    DateStyles,
    RightContainer,
    CalendarIconWrapper,
    DateSeparator,
} from './searchbar-section-styles'
import { getMonthLabel } from './utils'

import { DatepickerState } from './searchbar-section'
import AnalyticsService from '../../services/analytics-service'
import { useTranslation } from '../../hooks/locale'
import { getFormattedDate } from '../../utils/miscellaneous-utils'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { landingPageEventActions } from '../../services/analytics-service/actions'

import ArrivalIcon from '../../assets/icons/arrival.svg'

const DatepickerDynamic = dynamic<any>(() => import('react-components').then(mod => mod.Datepicker), { ssr: false })

interface Props {
    datepickerState: DatepickerState
    setDatepickerState: CallableFunction
    sticky: boolean
    url: string
    arrivalText: string
    departureText: string
}

const DateInputBox: React.FC<Props> = ({
    datepickerState,
    setDatepickerState,
    sticky,
    url,
    arrivalText,
    departureText,
}) => {
    const { isMobile, isDesktop } = useContext(ScreenContext)
    const { t } = useTranslation()
    const [checkInText, setCheckInDate] = useState(arrivalText)
    const [checkOutText, setCheckOutDate] = useState(departureText)

    const _monthLabelFormat = useMemo(
        () =>
            getMonthLabel([
                t('jan'),
                t('feb'),
                t('mar'),
                t('april'),
                t('may'),
                t('june'),
                t('july'),
                t('aug'),
                t('sept'),
                t('oct'),
                t('nov'),
                t('dec'),
            ]),
        [],
    )

    const [displayState, setDisplayState] = useState<DatepickerState>({
        startDate: datepickerState.startDate,
        endDate: datepickerState.endDate,
        focusedInput: 'startDate',
    })

    const [isVisible, setIsVisible] = useState(false)

    const containerRef = useRef(null)

    const handleOutsideClick = useCallback(e => {
        if (containerRef.current && containerRef.current.contains(e.target)) {
            return
        }
        handleClose()
    }, [])

    const handleLeftClick = useCallback(
        (data: DatepickerState) => {
            document.removeEventListener('click', handleOutsideClick)
            if (data.startDate) {
                setDatepickerState({
                    ...data,
                    focusedInput: 'startDate',
                })
            }
            setIsVisible(true)
            document.addEventListener('click', handleOutsideClick)
        },
        [setDatepickerState, setIsVisible, handleOutsideClick],
    )

    const handleRightClick = useCallback(
        (data: DatepickerState) => {
            document.removeEventListener('click', handleOutsideClick)
            if (data.startDate) {
                setDatepickerState({
                    ...data,
                    focusedInput: 'endDate',
                })
            }
            setIsVisible(true)
            document.addEventListener('click', handleOutsideClick)
        },
        [setDatepickerState, setIsVisible, handleOutsideClick],
    )

    const handleClose = useCallback(() => {
        setIsVisible(false)
        document.removeEventListener('click', handleOutsideClick)
    }, [setIsVisible, handleOutsideClick])

    const handleDatesChange = useCallback(
        (data: DatepickerState) => {
            if (!data.focusedInput) {
                setDatepickerState({ ...data, focusedInput: 'startDate' })

                if (data.endDate !== null) {
                    const m = data.endDate.getMonth() + 1
                    const y = data.endDate.getFullYear()
                    const showDate = '' + y + '-' + (m <= 9 ? '0' + m : m)
                    const eventCategory = `${landingPageEventCategories.searchSection.SEARCH_BOX} - ${url}`
                    const eventAction = landingPageEventActions.searchSection.searchBox.DEPARTURE_DATE
                    AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: showDate })
                }
            } else {
                setDatepickerState(data)

                if (data.startDate !== null) {
                    const m = data.startDate.getMonth() + 1
                    const y = data.startDate.getFullYear()
                    const showDate = '' + y + '-' + (m <= 9 ? '0' + m : m)
                    const eventCategory = `${landingPageEventCategories.searchSection.SEARCH_BOX} - ${url}`
                    const eventAction = landingPageEventActions.searchSection.searchBox.ARRIVAL_DATE
                    AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: showDate })
                }
            }
        },
        [setDatepickerState],
    )

    const handleMouseEnter = useCallback(
        (date: Date) => {
            if (datepickerState.focusedInput === 'startDate') {
                setDisplayState({ ...displayState, startDate: date })
            } else {
                setDisplayState({ ...displayState, endDate: date })
            }
        },
        [datepickerState, displayState, setDisplayState],
    )

    const handleMouseLeave = useCallback(() => {
        setDisplayState({ ...datepickerState })
    }, [datepickerState, displayState, setDisplayState])

    useEffect(() => {
        setDisplayState({ ...datepickerState })
        if (datepickerState.startDate && !datepickerState.endDate) {
            const date = datepickerState.startDate
            const text = getFormattedDate(date, false)
            setCheckInDate(text)
        } else if (datepickerState.startDate && datepickerState.endDate) {
            const date = datepickerState.endDate
            const text = getFormattedDate(date, false)
            setCheckOutDate(text)
            if (datepickerState.startDate.getFullYear() === datepickerState.endDate.getFullYear()) {
                setCheckInDate(getFormattedDate(datepickerState.startDate, true))
            }
            setIsVisible(false)
            document.removeEventListener('click', handleOutsideClick)
        } else {
            setCheckInDate(arrivalText)
            setCheckOutDate(departureText)
        }
    }, [datepickerState.startDate, datepickerState.endDate, setIsVisible, handleOutsideClick])

    return (
        <DateInputContainer ref={containerRef} sticky={sticky}>
            <LeftContainer
                clicked={isVisible && datepickerState.focusedInput === 'startDate'}
                onClick={() => handleLeftClick(datepickerState)}
            >
                <CalendarIconWrapper $clicked={isVisible && datepickerState.focusedInput === 'startDate'} />
                <DateStyles
                    clicked={
                        !!displayState.startDate &&
                        (datepickerState.focusedInput === 'startDate' || checkInText === arrivalText) &&
                        isVisible
                    }
                >
                    {checkInText}
                </DateStyles>
            </LeftContainer>
            <DateSeparator>-</DateSeparator>
            <RightContainer
                clicked={isVisible && datepickerState.focusedInput === 'endDate'}
                onClick={() => handleRightClick(datepickerState)}
            >
                <DateStyles
                    clicked={
                        !!displayState.endDate &&
                        (datepickerState.focusedInput === 'endDate' || checkOutText === departureText) &&
                        isVisible
                    }
                >
                    {checkOutText}
                </DateStyles>
            </RightContainer>
            {isVisible && (
                <DatepickerDynamic
                    zIndex={200}
                    left={'none'}
                    top={isDesktop ? '4rem' : '4.375rem'}
                    startDate={datepickerState.startDate}
                    endDate={datepickerState.endDate}
                    focusedInput={datepickerState.focusedInput}
                    translate={'none'}
                    onDatesChange={handleDatesChange}
                    headerIcon={<IconWrapper as={ArrivalIcon as AnyStyledComponent} />}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    onClose={handleClose}
                    arrivalText={t('chooseArrival')}
                    departureText={t('chooseDeparture')}
                    daysString={t('days')}
                    nightsString={t('nights')}
                    monthLabelFormat={_monthLabelFormat}
                    phrases={{
                        resetDates: t('reset'),
                        close: '',
                        datepickerStartDateLabel: '',
                        datepickerStartDatePlaceholder: '',
                        datepickerEndDateLabel: '',
                        datepickerEndDatePlaceholder: '',
                    }}
                    isMobile={isMobile}
                />
            )}
        </DateInputContainer>
    )
}

export default memo(DateInputBox)
