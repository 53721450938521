import styled, { css } from 'styled-components'
import { tabletBreakpoint, mobileBreakpoint, desktopBreakpoint } from 'react-components'
import ExternalLink from '../../common/external-link'

export const Container = styled(ExternalLink)(
    ({ theme }) => css`
        cursor: pointer;
        border-radius: ${theme.typography.border.radius.small};
        text-decoration: none;
        margin: 0 ${theme.spacing.xSmall} ${theme.spacing.xSmall} 0;

        @media only screen and (max-width: ${mobileBreakpoint.max}px) {
            height: 4.375rem;
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: 0 ${theme.spacing.large} 0 0;
            border-radius: ${({ theme }) => theme.typography.border.radius.xLarge};
        }
    `,
)

export const InnerContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.row};
        background-color: ${theme.colors.white};
        position: ${theme.style.position.relative};
        min-height: 4.5rem;
        max-height: 4.5rem;
        transition: box-shadow 200ms ease-out;
        border: ${theme.typography.border.width.xxxThin} ${theme.typography.border.style.solid}
            ${theme.colors.tfwBlackOpacity10};
        border-radius: ${({ theme }) => theme.typography.border.radius.xLarge};
        padding: 0.375rem;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            padding: 0;
            margin: 0 ${theme.spacing.medium} 0 0;
            flex-direction: ${theme.style.flexDirection.column};
            border: none;
            min-height: 19.1875rem;
            max-height: 19.1875rem;
            border-radius: ${theme.typography.border.radius.xLarge};
            max-width: 18.75rem;
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin: 0 ${theme.spacing.large} 0 0;
            max-width: 20rem;
        }
    `,
)

export const ImageContainer = styled.div(
    ({ theme }) => css`
        @media only screen and (max-width: ${mobileBreakpoint.max}px) {
            width: 5.625rem;
            height: 3.75rem;
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            width: ${theme.layout.fill};
            height: 13.9375rem;
        }

        img {
            border-radius: ${theme.typography.border.radius.small};

            @media only screen and (min-width: ${tabletBreakpoint.min}px) {
                border-radius: ${theme.typography.border.radius.xLarge} ${theme.typography.border.radius.xLarge} 0 0;
            }
        }
    `,
)

export const FooterContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.column};
        padding: 0.375rem ${theme.spacing.small} 0;

        @media only screen and (max-width: ${mobileBreakpoint.max}px) {
            width: 9.125rem;
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            padding: ${theme.spacing.small} ${theme.spacing.medium} ${theme.spacing.medium};
            border: ${theme.typography.border.width.xxxThin} ${theme.typography.border.style.solid}
                ${theme.colors.tfwBlackOpacity10};
            border-radius: 0 0 ${theme.typography.border.radius.xLarge} ${theme.typography.border.radius.xLarge};
            height: 5.25rem;
        }
    `,
)

export const TitleContainer = styled.h3(
    ({ theme }) => css`
        font-family: ${theme.typography.font.family.latoVerdana};
        font-size: ${theme.typography.font.size.small};
        line-height: 1.5rem;
        font-weight: ${theme.typography.font.lato.weight.black};
        margin: 0;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: ${theme.typography.font.size.large};
            line-height: 1.75rem;
            margin-bottom: ${theme.spacing.xxSmall};
            letter-spacing: -0.021875px;
        }
    `,
)

export const AccommodationContainer = styled.h4(
    ({ theme }) => css`
        color: ${theme.colors.tfwBlackOpacity50};
        font: ${theme.typography.font.weight.normal} ${theme.typography.font.size.xSmall} / 1rem
            ${theme.typography.font.family.latoVerdana};
        letter-spacing: 0.0125rem;
        margin: 0;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: ${theme.typography.font.size.medium};
            line-height: 1.5rem;
        }
    `,
)

export const RegionTitleContainer = styled.h3(
    ({ theme }) => css`
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${theme.typography.font.size.small};
        font-weight: ${theme.typography.font.weight.bold};
        line-height: 1.5;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-weight: 900;
            font-size: ${theme.typography.font.size.large};
            line-height: 1.75rem;
        }
    `,
)

export const SeoHref = styled(ExternalLink)`
    font: inherit;
    text-decoration: none;
`
