import styled, { css } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint, wideScreenBreakpoint } from 'react-components'
import { CarouselProvider, Slider } from 'pure-react-carousel'

import SunIcon from '../../assets/icons/sun.svg'

export const MainContainer = styled.section`
    max-width: 87.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.large};
    }
`
export const Container = styled.section`
    margin: 0;
`
export const TitleContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    margin: 2.5rem 1rem 0.75rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 2.5rem 0 0.75rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 0 0;
    }
`

export const Title = styled.h2`
    font-family: ${props => props.theme.typography.font.family.yummoVerdana};
    font-size: ${props => props.theme.typography.font.size.large};
    font-weight: 700;
    line-height: 1.75rem;
    margin: 0;
    color: ${props => props.theme.colors.tfwBlack87};
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        letter-spacing: -1px;
        font-size: 2.25rem;
        line-height: 2.625rem;
    }
`

export const TitleBubble = styled.div`
    position: ${props => props.theme.style.position.relative};
    height: fit-content;
    color: ${props => props.theme.colors.tfwRed};
    background-color: ${props => props.theme.colors.tfwRedOpacity08};
    border-radius: 0.375rem;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    font-weight: 900;
    font-size: ${props => props.theme.typography.font.size.medium};
    line-height: 1.5;
    padding: 0.5rem 1rem;
    margin: auto 0 auto 1rem;
`

export const Star = styled.img`
    position: ${props => props.theme.style.position.absolute};
    width: 0.5rem;
    height: 0.5rem;
    top: 0.375rem;
    right: 0.5625rem;
`

export const StarSmall = styled.img`
    position: ${props => props.theme.style.position.absolute};
    width: 0.25rem;
    height: 0.25rem;
    top: 0.8125rem;
    right: 0.375rem;
`

export const LinkContainer = styled.a`
    display: ${({ theme }) => theme.style.display.flex};
    font-size: 14px;
    line-height: 1.5rem;
    color: #2298da;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-weight: 700;
    margin: auto 0 auto auto;
    cursor: pointer;
    text-decoration: none;
`

export const SubtitleContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        justify-content: space-between;
        margin: 0 ${theme.spacing.medium};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: 0 0 ${theme.spacing.medium};
        }
    `,
)

export const Subtitle = styled.div`
    display: ${props => props.theme.style.display.flex};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-weight: 400;
    font-size: ${props => props.theme.typography.font.size.medium};
    margin: 0;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlackOpacity50};
    & > span > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: 500;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 0.5rem;
    }
`

export const CarouselContainer = styled.div`
    position: relative;
    max-width: ${props => props.theme.layout.fill};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        overflow: hidden;
    }
`

export const LoadingIcon = styled(SunIcon)`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`

export const StyledSlider = styled(Slider)<{ $stopSliderTransition?: boolean }>(
    ({ $stopSliderTransition, theme }) => css`
        padding: 0;
        ul {
            transition: transform 0.5s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
            min-width: ${theme.layout.fill};
        }

        ${$stopSliderTransition &&
        `ul {
            transition: none !important;
            }
        `}
    `,
)

export const StyledCarouselProvider = styled(CarouselProvider)<{ $customWidth?: boolean }>(
    ({ $customWidth, theme }) => css`
        width: ${theme.layout.fill};

        li {
            list-style: none;
        }

        ${$customWidth &&
        `li {
            @media only screen and (min-width: ${tabletBreakpoint.min}px) {
                width: 50% !important;
            }
            @media only screen and (min-width: ${desktopBreakpoint.min}px) {
                width: 33.34% !important;
            }
            @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
                width: 25% !important;
            }
        }`}

        & > .carousel__slider {
            padding-left: 4%;
            padding-right: 4%;
            @media only screen and (min-width: ${tabletBreakpoint.min}px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    `,
)
