import React, { FC, memo, useCallback, useContext, useMemo } from 'react'

import { ScreenContext } from 'react-components'

import VerticalPropertyCard from '../common/property-card/vertical-property-card'
import { ListingSectionObject } from '../../../transports/common/listing-section-transport'
import { landingPageEventCategories } from '../../../services/analytics-service/categories'
import { landingPageEventActions } from '../../../services/analytics-service/actions'
import AnalyticsService from '../../../services/analytics-service'
import { useTranslation } from '../../../hooks/locale'
import { getPropertyClickRedirectUrl } from '../utils'
import { usePropertyCardTracking } from '../hooks'
import { encodeToBase64 } from '../../../utils/encoding-utils'
import { isServer } from '../../../utils/platform-utils'

interface Props {
    listingObject: ListingSectionObject
    isLoading: boolean
    url: string
    listingType?: string
    position: number
}

interface ServerProps {
    requestUrl?: string
}

const ListingSectionVerticalCard: FC<Props & ServerProps> = ({
    listingObject,
    url,
    listingType,
    position,
    requestUrl,
}) => {
    const { t } = useTranslation()
    const { isMobile } = useContext(ScreenContext)

    const imageAspectRatio = useMemo(() => (isMobile ? '66.67%' : '63%'), [isMobile])
    const { propertyCardClickTracking } = usePropertyCardTracking(listingObject, listingType)
    const handleCardClick = useCallback(() => {
        const eventCategory = `${landingPageEventCategories.listingCardSection.TOP_INSERAT} - ${url}`
        const eventAction = landingPageEventActions.listingCardSection.TOP_INSERAT
        const redirectUrl = getPropertyClickRedirectUrl(t('traumBaseUrl'), listingObject.href)

        // handle traumBoost tracking
        const _objectURL = new URL(redirectUrl)
        if (listingObject.isPremiumTopObject || listingObject.isTopObject) {
            // handle traumBoost tracking
            // from_page & tb will only be set for all top listings in RLP
            // from_page contains base64 encoded url of the page from which the request is made
            _objectURL.searchParams.set(
                'from_page',
                isServer ? encodeToBase64(requestUrl ?? '') : encodeToBase64(window.location.href),
            )
            _objectURL.searchParams.set('tb', '1')
        }

        propertyCardClickTracking(listingObject, listingType)

        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: redirectUrl })
        window.open(_objectURL.href, '_blank')
    }, [url, t, listingObject, propertyCardClickTracking, listingType])

    return (
        <VerticalPropertyCard
            listingObject={listingObject}
            transitionTime={0.3}
            dragEnabled={false}
            naturalSlideHeight={1}
            naturalSlideWidth={1}
            visibleSlides={1}
            step={1}
            handleCardContentClick={handleCardClick}
            listingType={listingType}
            imageAspectRatio={imageAspectRatio}
            position={position}
        />
    )
}

export default memo(ListingSectionVerticalCard)

export async function getServerSideProps({ req }: { req: any }) {
    console.log(req)
    return { props: { requestUrl: req.url } }
}
