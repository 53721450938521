import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { WithContext, Product } from 'schema-dts'

import { BaseTransport, RegionLandingPageTransport } from '../transports'
import { deleteSession } from '../utils/delete-session'
import { saveHistoryItem } from '../redux/searchbar'
import Page from '../components/common/page'
import { LandingPage } from '../components'
import { getLandingPageUrlFromRoute } from '../utils/route-utils'
import { useTranslation } from '../hooks/locale'
import { fastlaneClient, pageClient } from '../clients'
import { ResponseLocals, PageProps } from '../common/types'
import { GetServerSideProps } from '../redux/types'
import { RegionLandingPageParamsTransport } from '../transports/region-landing-page-transport'
import { getMessagesFromLanguage } from '../i18n/utils'
import { getRoutesFromDomain } from '../routes/utils'
import { useUserDetails } from '../hooks/users'
import { BaseSearchbarHistoryItem } from '../redux/searchbar/types'
import BreadcrumbTransport from '../transports/common/breadcrumb-transport'
import { useSetWishlistCount } from '../hooks/wishlist'
import { modifyCanonicalOptionally } from '../utils/seo-utils'

type Props = {
    baseLandingPageTransport: BaseTransport<RegionLandingPageTransport>
    sitePathname: string
} & PageProps

const _getLocationName = (
    breadcrumbs: BreadcrumbTransport[],
    continentName: string,
    countryName?: string,
    regionName?: string,
    subregionName?: string,
) => subregionName || regionName || countryName || continentName || breadcrumbs[breadcrumbs.length - 1].title

const _getProductSchemaHTML = (landingPageTransport: RegionLandingPageTransport | null): { __html: string } | null => {
    if (
        !landingPageTransport ||
        !landingPageTransport.topRatedObjects ||
        !landingPageTransport.topRatedObjects.averageRating ||
        !landingPageTransport.topRatedObjects.totalRatingCount ||
        landingPageTransport.topRatedObjects.totalRatingCount <= 0 ||
        landingPageTransport.topRatedObjects.averageRating < 1
    ) {
        return null
    }

    const schema: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: landingPageTransport.hero.description.title,
        description: landingPageTransport.head.description,
        brand: {
            '@type': 'Brand',
            name: 'Traum-Ferienwohnungen',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: '5',
            worstRating: '1',
            ratingValue: landingPageTransport.topRatedObjects.averageRating,
            reviewCount: landingPageTransport.topRatedObjects.totalRatingCount,
        },
    }

    if (landingPageTransport.hero.slide?.image) {
        schema.image = landingPageTransport.hero.slide?.image?.url
    }

    return {
        __html: JSON.stringify(schema),
    }
}

const LandingPageContainer: FC<Props> = ({ baseLandingPageTransport, sitePathname }) => {
    const { data: landingPageTransport } = baseLandingPageTransport
    const {
        staticData,
        head,
        hero,
        recommendedObjects,
        popularDestinations,
        topRatedObjects,
        topOffers,
        typicalAccommodations,
        objectsByPopularFilters,
        popularFilters,
        regions,
        holidayIdeas,
        breadcrumbs,
        directoryPageResponse,
    } = landingPageTransport

    const router = useRouter()
    const url = getLandingPageUrlFromRoute(router.query)
    const { continentName, countryName, regionName, subregionName } = staticData
    const locationName = _getLocationName(breadcrumbs, continentName, countryName, regionName, subregionName)
    const { setWishlistCount } = useSetWishlistCount()

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { userDetails } = useUserDetails()
    const isLoggedIn = !!userDetails?.id

    const addToHistory = useCallback(
        (historyItem: BaseSearchbarHistoryItem) => dispatch(saveHistoryItem(historyItem)),
        [dispatch],
    )

    const DEPRECATED_searchFunction = useCallback(
        async ({
            arrival,
            departure,
            persons,
            search,
            regionId,
        }: {
            arrival: string
            departure: string
            persons: number
            search: string
            regionId: number
        }) => {
            try {
                const response = await fastlaneClient.getSearchUrl({ arrival, departure, persons, regionId, search })
                document.location.href = `${t('traumBaseUrl')}${response.data.searchurl}`
            } catch {}
        },
        [t],
    )

    useEffect(() => {
        setWishlistCount()
    }, [isLoggedIn, setWishlistCount, userDetails])

    const productSchemaHTML = useMemo(() => _getProductSchemaHTML(landingPageTransport), [landingPageTransport])

    return (
        <Page head={head}>
            {!!productSchemaHTML && (
                <Head>
                    <script type="application/ld+json" dangerouslySetInnerHTML={productSchemaHTML} />
                </Head>
            )}
            <LandingPage
                sitePathname={sitePathname}
                url={url}
                isLoggedIn={isLoggedIn}
                firstName={userDetails?.firstName}
                hero={hero}
                locationName={locationName}
                recommendedObjects={recommendedObjects}
                addToHistory={addToHistory}
                searchFunction={DEPRECATED_searchFunction}
                staticData={staticData}
                popularDestinationsSection={popularDestinations}
                topRatedObjectsSection={topRatedObjects}
                topOfferListingsSection={topOffers}
                typicalAccommodationsSection={typicalAccommodations}
                popularFilterListingsSections={objectsByPopularFilters}
                popularFiltersSection={popularFilters}
                regionsSection={regions}
                holidayIdeasSection={holidayIdeas}
                breadcrumbs={breadcrumbs}
                directoryPageResponse={directoryPageResponse}
            />
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ req, res }) => {
    const { user, device } = res.locals as ResponseLocals

    const { continent, country, region, subregion } = req.params as unknown as RegionLandingPageParamsTransport
    const params: RegionLandingPageParamsTransport = { continent, country }
    const path = req.url || '/'
    params.requestPath = path
    if (region) {
        params.region = region
    }
    if (subregion) {
        params.subregion = subregion
    }

    const sitePathname = req.path
    const data = await pageClient.getRegionLandingPage({ user, params })
    if (data.status === 404) {
        return {
            notFound: true,
        }
    }
    modifyCanonicalOptionally(req, data)
    const requestUserConfig = deleteSession(user)
    return {
        props: {
            baseLandingPageTransport: data,
            device,
            requestUserConfig: requestUserConfig,
            messages: await getMessagesFromLanguage(user.language),
            routes: await getRoutesFromDomain(user.domain),
            sitePathname,
        },
    }
}

export default LandingPageContainer
