import { useDispatch, useSelector } from 'react-redux'
import { setCountTransport } from '../redux/wishlist/actions'
import { getUserDetailsModel } from '../redux/user/selectors'
import CookieManagerService from '../services/cookie-manager-service'

export const useSetWishlistCount = () => {
    const dispatch = useDispatch()
    const userDetails = useSelector(getUserDetailsModel)
    const isLoggedIn = !!userDetails?.id
    function setWishlistCount() {
        let count
        if (isLoggedIn && userDetails?.favoriteCount) {
            count = userDetails?.favoriteCount
        }
        if (!isLoggedIn && CookieManagerService.getCookie(CookieManagerService.FAVORITE_LISTINGS_IDS)) {
            count = CookieManagerService.getCookie(CookieManagerService.FAVORITE_LISTINGS_IDS)?.split(',').length
        }
        dispatch(setCountTransport(count))
    }
    return { setWishlistCount }
}
