import styled, { css } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel'

export const Container = styled.section`
    width: inherit;
    padding: 0 1rem 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 1.5rem;
    }
`

export const TitleContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    justify-content: ${props => props.theme.style.justifyContent.flexStart};
`

export const Title = styled.h2`
    margin: 2.5rem 0 0;
    font-size: ${({ theme }) => theme.typography.font.size.large};
    font-weight: 700;
    font-family: ${({ theme }) => theme.typography.font.family.yummoVerdana};
    line-height: 1.75rem;
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        line-height: 2.625rem;
        font-size: 2.25rem;
        letter-spacing: -1px;
        margin: 2.5rem 0 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 0 0;
    }
`

export const TitleBubble = styled.div`
    position: ${props => props.theme.style.position.relative};
    height: fit-content;
    color: ${props => props.theme.colors.feedbackGreen};
    background: ${props => props.theme.colors.feedbackGreen20};
    border-radius: ${props => props.theme.typography.border.radius.small};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-style: normal;
    font-weight: 900;
    font-size: ${props => props.theme.typography.font.size.medium};
    line-height: 1.5;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    margin-top: 3.75rem;
`

export const Star = styled.img`
    position: ${props => props.theme.style.position.absolute};
    width: 0.5rem;
    height: 0.5rem;
    top: 0.375rem;
    right: 0.5625rem;
`

export const StarSmall = styled.img`
    position: ${props => props.theme.style.position.absolute};
    width: 0.25rem;
    height: 0.25rem;
    top: 0.8125rem;
    right: 0.375rem;
`

export const SubTitle = styled.p`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-family: ${props => props.theme.typography.font.family.latoVerdana};
        font-size: ${props => props.theme.typography.font.size.medium};
        font-weight: 400;
        line-height: 1.5rem;
        color: ${props => props.theme.colors.tfwBlackOpacity50};
        margin: 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 0.5rem;
    }
`

export const CarouselContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    transition: 200ms filter linear;
    margin-top: 1rem;
    .slick-list {
        padding: 0 !important;
    }
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        .slick-track {
            margin-left: 0;
        }
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: ${props => props.theme.spacing.large} -${props => props.theme.spacing.large} 0;
        padding-left: ${props => props.theme.spacing.large};
        overflow: hidden;
    }
`

export const StyledButton = styled.div<{ $left?: boolean; $right?: boolean; disabled?: boolean }>`
    position: ${props => props.theme.style.position.absolute};
    top: 51.09%;
    cursor: pointer;
    left: ${props => (props.$left ? '0' : 'unset')};
    right: ${props => (props.$right ? '0' : 'unset')};
    transform: translateY(-50%);
    background-color: ${props => props.theme.colors.white};
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    display: flex;
    flex-direction: ${props => props.theme.style.flexDirection.column};
    align-items: ${props => props.theme.style.alignItems.center};
    justify-content: ${props => props.theme.style.justifyContent.center};
    transition: opacity 200ms ease-in;
    z-index: 10;
    box-shadow: 0 0.5rem 0.625rem 0.0625rem #21212123, 0 0.1875rem 0.875rem 0.1875rem #2121210f,
        0 0.25rem 0.625rem 0 #21212119;
    &:hover {
        opacity: 1;
        transition: opacity 200ms ease-out;
    }
`

export const DesktopCarouselButtonStyles = css(
    () => css`
        display: none;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            display: flex;
        }
    `,
)

export const StyledButtonBack = styled(StyledButton).attrs({ as: ButtonBack })`
    ${DesktopCarouselButtonStyles}
`

export const StyledButtonNext = styled(StyledButton).attrs({ as: ButtonNext })`
    ${DesktopCarouselButtonStyles}
`

export const CarouselIcon = styled.img`
    height: 1.5rem;
    width: 1.5rem;
`

export const StyledCarouselProvider = styled(CarouselProvider)`
    width: ${props => props.theme.layout.fill};
    li {
        list-style: none;
    }
    & > .carousel__slider {
        padding-right: 6%;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0;
        }
    }
`

export const StyledSlider = styled(Slider)<{ $stopSliderTransition?: boolean }>(
    ({ $stopSliderTransition }) => css`
        padding: 0;
        ul {
            transition: transform 0.5s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
        }

        ${$stopSliderTransition &&
        `ul {
            transition: none !important;
            }
        `}
    `,
)
