import React, { memo, useContext } from 'react'
import {
    HolidayWrapper,
    LocationIcon,
    RegionTitle,
    VerticalSeparator,
    RegionSubtitleContainer,
    RegionSubtitle,
    ChildrenRegionContainer,
    GrandChildrenRegionContainer,
} from './vacation-region-styles'
import { RegionTransport } from '../../transports/region-landing-page-transport'
import { ScreenContext } from 'react-components'
import ExternalLink from '../common/external-link'

const VacationRegion: React.FC<RegionTransport> = ({ url, name, subregions, noCrawl }) => {
    const { isMobile } = useContext(ScreenContext)
    return (
        <HolidayWrapper hasSubtitle={!!subregions && subregions.length > 0 && !!subregions[0].url}>
            <ChildrenRegionContainer>
                <LocationIcon />
                <ExternalLink title={name} as={RegionTitle} href={url} noCrawl={noCrawl}>
                    {name}
                </ExternalLink>
                {!isMobile && subregions && subregions.length > 0 && subregions[0].url && <VerticalSeparator />}
            </ChildrenRegionContainer>
            <GrandChildrenRegionContainer>
                {subregions?.map(
                    (item, index) =>
                        item.url && (
                            <RegionSubtitleContainer key={item.url}>
                                <ExternalLink as={RegionSubtitle} href={item.url} noCrawl={item.noCrawl}>
                                    {item.title}
                                    {index !== subregions.length - 1 && ', '}
                                </ExternalLink>
                            </RegionSubtitleContainer>
                        ),
                )}
            </GrandChildrenRegionContainer>
        </HolidayWrapper>
    )
}

export default memo(VacationRegion)
