import styled, { css } from 'styled-components'
import { desktopBreakpoint, GhostButton, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import { LocationPinIcon } from '../../../common/icons'
import ExternalLink from '../../../common/external-link'

export const CardContainer = styled.div(
    ({ theme }) => css`
        margin-right: ${theme.spacing.xSmall};
        border-radius: ${theme.typography.border.radius.xLarge};
        cursor: pointer;
    `,
)

export const OverflowHiddenCell = styled.span(
    ({ theme }) => css`
        overflow: ${theme.style.overflow.hidden};
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
)

export const PropertyContextContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        width: ${theme.layout.fill};
    `,
)

export const Container = styled.div(
    ({ theme }) => css`
        margin: 0 ${theme.spacing.xSmall} 0 0;
        cursor: pointer;
        border-radius: ${theme.typography.border.radius.small};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: 0 ${theme.spacing.medium} 0 0;
        }
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin: 0 ${theme.spacing.large} 0 0;
        }
    `,
)

export const InnerContainer = styled.div(
    ({ theme }) => css`
        background-color: ${theme.colors.white};
        position: ${theme.style.position.relative};
        min-height: 23.6875rem;
        transition: box-shadow 200ms ease-out;
        border-radius: ${theme.typography.border.radius.xLarge};
    `,
)

export const FavouriteButtonContainer = styled.div(
    ({ theme }) => css`
        position: ${theme.style.position.absolute};
        top: 0.375rem;
        left: auto;
        right: ${theme.spacing.xSmall};
        z-index: 1;
    `,
)

export const Pill = styled.div(
    ({ theme }) => css`
        font: 0.875rem/1.5rem ${theme.typography.font.family.latoVerdana};
        position: ${theme.style.position.absolute};
        height: ${theme.layout.small};
        z-index: 10;
        left: 0;
        top: 0;
        border-radius: ${theme.typography.border.radius.xLarge} 0;
        padding: 0 ${theme.spacing.small};
        color: ${theme.colors.tfwBlack87};
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
    `,
)

export const RedPill = styled(Pill)(
    ({ theme }) => css`
        background: ${theme.colors.tfwErrorRed};
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.tfwErrorRed};
        width: max-content;
        z-index: 1;
    `,
)

export const RegionName = styled.div(
    ({ theme }) => css`
        font: ${theme.typography.font.size.small} / 1.5rem ${theme.typography.font.family.latoVerdana};
        padding: 0 0 ${theme.spacing.xxSmall};
        color: ${theme.colors.tfwBlack87};
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        text-decoration: none;
        max-width: 58%;
    `,
)

export const RegionPinIcon = styled(LocationPinIcon)(
    ({ theme }) => css`
        margin-right: 0.1875rem;
        width: ${theme.layout.xxSmall};
        height: ${theme.layout.xxSmall};
        flex-shrink: 0;
    `,
)

export const RegionNameText = styled(OverflowHiddenCell)``

export const RatingsText = styled(RegionNameText)(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.small};
    `,
)

export const VerticalSeparator = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        width: 0.0625rem;
        height: 1.5rem;
        margin: 0 ${theme.spacing.xSmall} ${theme.spacing.xxSmall};
        background: ${theme.colors.tfwBlackOpacity10};
        flex-shrink: 0;
    `,
)

export const RatingsContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.row};
        padding: 0 0 ${theme.spacing.xxSmall};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
)

export const RatingsIcon = styled.img(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        margin: auto 0.1875rem auto 0;
        width: ${theme.layout.xxSmall};
        height: ${theme.layout.xxSmall};
        flex-shrink: 0;
    `,
)

export const Title = styled.h3(
    ({ theme }) => css`
        margin: 0;
        width: ${theme.layout.fill};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: ${theme.typography.font.family.latoVerdana};
        color: ${theme.colors.tfwBlack87};
        font-size: ${theme.typography.font.size.large};
        font-weight: 600;
        line-height: 1.5;
    `,
)

export const SeoHref = styled(ExternalLink)`
    font: inherit;
    text-decoration: none;
`

export const StatsContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        width: ${theme.layout.fill};
        font-size: 0.6875rem;
        font-family: ${theme.typography.font.family.latoVerdana};
        color: ${theme.colors.tfwBlack87};
        font-weight: 700;
        overflow: auto;
        padding: 0;
        list-style: none;
        margin-top: 0.75rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: uppercase;
        line-height: 1rem;
        letter-spacing: 0.4px;
    `,
)

export const RegionTextSeparator = styled.span(
    ({ theme }) => css`
        display: list-item;
        margin: 0 ${theme.spacing.xxSmall};
        font-weight: 300;
    `,
)

export const RegionTextItem = styled.span`
    display: inline-block;
    padding: 0;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const FooterInnerWrapper = styled.div<{ listingType?: string }>(
    ({ theme, listingType }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.column};
        width: 45%;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            width: ${!!listingType ? '70%' : '52%'};
        }
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            width: ${!!listingType ? '70%' : '50%'};
        }
        @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
            letter-spacing: -0.19px;
            width: ${!!listingType ? '70%' : '47%'};
        }
    `,
)

interface PriceProps {
    hasPrice: boolean
}
export const PriceText = styled(OverflowHiddenCell)<PriceProps>(
    ({ theme, hasPrice }) => css`
        font-weight: 900;
        font-family: ${theme.typography.font.family.latoVerdana};
        font-size: 1rem;
        line-height: 1.5rem;
        color: ${theme.colors.tfwBlack87};
        ${hasPrice && 'width: 100%'};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    `,
)

export const NoDiscountPriceText = styled.span(
    ({ theme }) => css`
        font-weight: ${theme.typography.font.weight.bold};
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: ${theme.typography.font.family.latoVerdana};
        color: #0000004c;
        text-decoration-line: line-through;
        margin-left: 0.375rem;
    `,
)

export const GuestText = styled.span(
    ({ theme }) => css`
        font-size: 0.5625rem;
        font-family: ${theme.typography.font.family.latoVerdana};
        color: ${theme.colors.tfwBlack38};
        line-height: 1rem;
        font-weight: ${theme.typography.font.lato.weight.bold};
        text-transform: uppercase;
        margin-top: ${theme.spacing.xxxSmall};
        letter-spacing: 0.025rem;
    `,
)

export const CardFooter = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: ${theme.spacing.medium};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            height: 2.75rem;
        }
    `,
)

export const StyledGhostButton = styled(GhostButton)(
    ({ theme }) => css`
        color: ${theme.colors.tfwBlack87};
        background-color: ${theme.colors.white};
        padding: ${theme.spacing.xSmall} ${theme.spacing.medium};
        border-color: ${theme.colors.tfwBlack87};
        width: fit-content;
        &:hover {
            background-color: ${theme.colors.tfwYellowHover};
            color: ${theme.colors.tfwBlack87};
            border-color: ${theme.colors.tfwYellowHover};
        }
    `,
)

export const ButtonText = styled.div(
    ({ theme }) => css`
        font-weight: ${theme.typography.font.weight.bold};
        font-size: ${theme.typography.font.size.small};
        line-height: 1.5rem;
    `,
)
