import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { StyledButtonBack, CarouselIcon } from './styles'
import { ChevronLeftIcon } from '../../common/icons'

const CarouselButtonBack: FC<{}> = () => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex !== 0 ? (
        <StyledButtonBack $left>
            <CarouselIcon as={ChevronLeftIcon as AnyStyledComponent} />
        </StyledButtonBack>
    ) : null
}

export default memo(CarouselButtonBack)
