import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { FilterIconImage } from './filter-icon-styles'

import ACIcon from '../../assets/icons/filter-icons/ac.svg'
import BabybettIcon from '../../assets/icons/filter-icons/babybett.svg'
import BalconyIcon from '../../assets/icons/filter-icons/balcony.svg'
import DishwasherIcon from '../../assets/icons/filter-icons/dishwasher.svg'
import GardenIcon from '../../assets/icons/filter-icons/garden.svg'
import GrillIcon from '../../assets/icons/filter-icons/grill.svg'
import PoolIcon from '../../assets/icons/filter-icons/pool.svg'
import SaunaIcon from '../../assets/icons/filter-icons/sauna.svg'
import TelevisionIcon from '../../assets/icons/filter-icons/television.svg'
import TerraceIcon from '../../assets/icons/filter-icons/terrace.svg'
import WashingMachineIcon from '../../assets/icons/filter-icons/washing-machine.svg'
import WifiIcon from '../../assets/icons/filter-icons/wifi.svg'

interface Props {
    filterKey: string
    alt?: string
}

export const getFilterIconComponent = (filterKey: string) => {
    switch (filterKey) {
        case 'Waschmaschine':
            return WashingMachineIcon
        case 'Geschirrspüler':
            return DishwasherIcon
        case 'Fernseher':
            return TelevisionIcon
        case 'WLAN':
            return WifiIcon
        case 'Sauna':
            return SaunaIcon
        case 'Swimmingpool':
            return PoolIcon
        case 'Garten':
            return GardenIcon
        case 'Terrasse':
            return TerraceIcon
        case 'Balkon':
            return BalconyIcon
        case 'Grill':
            return GrillIcon
        case 'Babybett':
            return BabybettIcon
        case 'Klimaanlage':
        default:
            return ACIcon
    }
}

const FilterIcon: FC<Props> = ({ filterKey, ...restProps }) => {
    const filterIconComponent = getFilterIconComponent(filterKey)
    return filterIconComponent ? (
        <FilterIconImage {...restProps} as={filterIconComponent as AnyStyledComponent} />
    ) : null
}

export default memo(FilterIcon)
