import React, { FC, memo } from 'react'
import { Slide } from 'pure-react-carousel'

import ListingSectionTransport from '../../../transports/common/listing-section-transport'
import ReviewsCard from '../../app-components/reviews-card'
import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { useLoadMoreSlides } from '../hooks'

interface Props {
    listings: ListingSectionTransport['objects']
    loadMoreListings: () => void
    visibleSlides: number
    isLoading: boolean
    finalTotalReviewCount: number
}

const Cards: FC<Props> = ({ listings, loadMoreListings, visibleSlides, finalTotalReviewCount }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    useLoadMoreSlides({
        currentSlideIndex: _currentSlideIndex,
        visibleSlides,
        currentTotalSlideCount: listings.length,
        loadMoreSlides: loadMoreListings,
        finalTotalSlideCount: finalTotalReviewCount,
    })

    return (
        <>
            {listings.map((listing, index) => (
                <Slide key={`${index}_${listing.id}`} index={index}>
                    <ReviewsCard listing={listing} key={listing.id} position={index} />
                </Slide>
            ))}
        </>
    )
}

export default memo(Cards)
