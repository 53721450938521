import React, { FC, memo } from 'react'
import { CarouselLeftIcon, StyledButtonBack } from './styles'
import { useCurrentSlideIndex } from '../../../hooks/carousel'

const CarouselButtonBack: FC<{}> = () => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex > 0 ? (
        <StyledButtonBack $left>
            <CarouselLeftIcon />
        </StyledButtonBack>
    ) : null
}

export default memo(CarouselButtonBack)
