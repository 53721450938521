import styled, { css } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

import { LocationPinIcon } from '../common/icons'

export const Container = styled.section`
    padding: 0 1rem;
    width: 100%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: flex;
        flex-wrap: wrap;
        padding: 0 1.5rem;
    }
`

export const Title = styled.h2`
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 700;
    font-family: ${({ theme }) => theme.typography.font.family.yummo};
    letter-spacing: -0.35px;
    margin: 2.5rem 0 0.25rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 2.5rem 0 1rem;
        font-size: 2.25rem;
        width: 100%;
        line-height: 2.625rem;
        letter-spacing: -1px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 0 1.5rem;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 1.25rem;
    width: 100%;
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
`

export const HolidayWrapper = styled.div<{ hasSubtitle: boolean }>`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    padding: 0.75rem 0;
    border-bottom: ${props => props.theme.typography.border.width.xxxThin} solid
        ${props => props.theme.colors.tfwBlackOpacity10};
    background: ${props => props.theme.colors.white};
    margin-bottom: 0;
    margin-right: 0;
    ${props => props.hasSubtitle && 'width: 100%'};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${props => props.theme.style.flexDirection.row};
        border: ${props => props.theme.typography.border.width.xxxThin} solid
            ${props => props.theme.colors.tfwBlackOpacity10};
        border-radius: ${props => props.theme.typography.border.radius.xLarge};
        padding: 1rem 1.5rem;
        margin-bottom: ${props => props.theme.spacing.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 1.5rem;
        ${props => props.hasSubtitle && 'width: fit-content'};
        margin-right: ${props => props.theme.spacing.medium};
    }
`

export const LocationIcon = styled(LocationPinIcon)`
    height: 1rem;
    width: 1rem;
    margin: auto 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 1.5rem;
        width: 1.5rem;
    }
`

export const RegionSubtitle = styled.a(
    ({ theme }) => css`
        text-decoration: none;
        line-height: 1.5rem;
        font-size: ${theme.typography.font.size.small};
        font-family: ${theme.typography.font.family.latoVerdana};
        color: ${theme.colors.tfwBlack87};
        font-weight: ${theme.typography.font.weight.normal};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: ${theme.typography.font.size.medium};
        }
    `,
)

export const RegionTitle = styled(RegionSubtitle)(
    ({ theme }) => css`
        margin: auto 0 auto ${theme.spacing.xSmall};
        font-weight: 900;
    `,
)

export const VerticalSeparator = styled.div(
    ({ theme }) => css`
        width: ${theme.spacing.large};
        height: 0;
        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        transform: rotate(90deg);
        align-self: ${theme.style.textAlign.center};
        margin: 0;
    `,
)

export const RegionSubtitleContainer = styled.span`
    white-space: pre-wrap;
`

export const ChildrenRegionContainer = styled.div`
    display: flex;
`
export const GrandChildrenRegionContainer = styled.div`
    display: flex;
    margin-left: 1.5rem;
    flex-wrap: wrap;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-left: 0;
    }
`
