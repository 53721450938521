import React, { FC, memo, useCallback, useMemo } from 'react'

import {
    Container,
    InformationContainer,
    ImageContainer,
    TopLeftContainer,
    BodyTopContainer,
    BodyBottomContainer,
    TopRightContainer,
} from './vertical-card-styles'
import ImageTransport from '../../transports/common/image-transport'
import { getContent } from '../utils'
import VerticalCardTopLeft from './vertical-card-components/vertical-card-top-left'
import ImageCarousel from './vertical-card-components/image-carousel'
import VerticalCardBodyTop from './vertical-card-components/vertical-card-body-top'
import VerticalCardBodyBottom from './vertical-card-components/vertical-card-body-bottom'
import VerticalCardTopRight from './vertical-card-components/vertical-card-top-right'
import VerticalCardImageContainer from './vertical-card-components/vertical-card-image-container'

/* If custom carousel needed dont give prop value to images and use VerticalCardImageContainer to bring image data
    to vertical card. snippetPadding is for padding on information container and other border properties are for the
    whole vertical card container*/

type Props = {
    children: JSX.Element | React.ReactNode
    images?: ImageTransport[]
    onCardClick?: CallableFunction
    snippetPadding?: string
    borderRadius?: string
    borderWidth?: string
    borderStyle?: string
    borderColor?: string
    imageAspectRatio?: string
}

const VerticalCard: FC<Props> = ({
    children,
    images,
    onCardClick,
    snippetPadding,
    borderColor,
    borderRadius,
    borderStyle,
    borderWidth,
    imageAspectRatio,
}) => {
    const childrenContent = children

    const topLeftContent = useMemo(() => getContent(childrenContent, VerticalCardTopLeft), [childrenContent])
    const topRightContent = useMemo(() => getContent(childrenContent, VerticalCardTopRight), [childrenContent])
    const imageContent = useMemo(() => getContent(childrenContent, VerticalCardImageContainer), [childrenContent])
    const bodyTopContent = useMemo(() => getContent(childrenContent, VerticalCardBodyTop), [childrenContent])
    const bodyBottomContent = useMemo(() => getContent(childrenContent, VerticalCardBodyBottom), [childrenContent])

    const onCardContentClick = useCallback(() => {
        if (!!onCardClick) {
            onCardClick()
        }
    }, [onCardClick])

    return (
        <Container
            borderStyle={borderStyle}
            borderWidth={borderWidth}
            borderRadius={borderRadius}
            borderColor={borderColor}
            {...(!!onCardClick && { onClick: onCardContentClick })}
        >
            <ImageContainer>
                {!!topLeftContent && <TopLeftContainer>{topLeftContent}</TopLeftContainer>}
                {!!images ? <ImageCarousel images={images} aspectRatio={imageAspectRatio} /> : imageContent}
                {!!topRightContent && <TopRightContainer>{topRightContent}</TopRightContainer>}
            </ImageContainer>
            <InformationContainer padding={snippetPadding}>
                {!!bodyTopContent && <BodyTopContainer>{bodyTopContent}</BodyTopContainer>}
                {!!bodyBottomContent && <BodyBottomContainer>{bodyBottomContent}</BodyBottomContainer>}
            </InformationContainer>
        </Container>
    )
}

export default memo(VerticalCard)
