import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { TipTransport } from '../../transports/region-landing-page-transport'
import {
    CrossButtonContainer,
    CrossIcon,
    OverLayContainer,
    OverlayContentContainer,
    OverlayHeading,
    OverlayHeadSection,
    OverlayMarkdownContainer,
    OverlayOuterContentContainer,
    OverlaySubTitle,
    OverlayTitle,
} from './tips-section-styles'
import { CallbackFunction } from '../../common/types'

import CloseIcon from '../../assets/icons/close.svg'

type Props = {
    tip: TipTransport
    toggleOverlay: CallbackFunction
}

const TipsSectionOverlay: FC<Props> = ({ tip, toggleOverlay }) => {
    return (
        <OverLayContainer>
            <OverlayOuterContentContainer>
                <OverlayHeadSection>
                    <CrossButtonContainer onClick={toggleOverlay}>
                        <CrossIcon as={CloseIcon as AnyStyledComponent} />
                    </CrossButtonContainer>
                    <OverlayHeading>
                        <OverlayTitle>{tip.title}</OverlayTitle>
                        <OverlaySubTitle>{tip.subtitle}</OverlaySubTitle>
                    </OverlayHeading>
                </OverlayHeadSection>
                <OverlayContentContainer>
                    <OverlayMarkdownContainer>
                        <ReactMarkdown>{tip.text}</ReactMarkdown>
                    </OverlayMarkdownContainer>
                </OverlayContentContainer>
            </OverlayOuterContentContainer>
        </OverLayContainer>
    )
}

export default memo(TipsSectionOverlay)
