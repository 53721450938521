import React, { FC, useMemo } from 'react'
import { Slide } from 'pure-react-carousel'

import {
    CarouselContainer,
    CarouselImage,
    ImageContainer,
    StyledCarouselProvider,
    StyledSlider,
} from './image-carousel-styles'
import CarouselButtonBack from './carousel-button-back'
import { DotsContainer } from './dots-container'
import CarouselButtonNext from './carousel-button-next'
import ImageTransport from '../../../../transports/common/image-transport'
import ImageLoadingComponent from '../../../app-components/image-loading-component'

interface Props {
    images: ImageTransport[]
    transitionTime: number
    dragEnabled: boolean
    naturalSlideHeight: number
    naturalSlideWidth: number
    visibleSlides: number
    step: number
    borderRadius?: string
    aspectRatio?: string
    height?: string
    defaultAltText?: string
}

const getSliderImages = (images: ImageTransport[], aspectRatio?: string, height?: string, defaultAltText?: string) => {
    return images.map((image, index) => (
        <Slide key={`${image.url}_${index}`} index={index}>
            <CarouselImage key={`${image.url}_${index}`} height={height}>
                <ImageLoadingComponent
                    src={image.url}
                    alt={!!image.alt ? image.alt : `${defaultAltText} - image${index + 1}`}
                    paddingTop={aspectRatio}
                />
            </CarouselImage>
        </Slide>
    ))
}

export const ImageCarousel: FC<Props> = ({
    images,
    dragEnabled,
    naturalSlideHeight,
    naturalSlideWidth,
    visibleSlides,
    step,
    transitionTime,
    borderRadius,
    aspectRatio,
    height,
    defaultAltText,
}) => {
    const numberOfImages = images.length
    const sliderImages = useMemo(
        () => getSliderImages(images, aspectRatio, height, defaultAltText),
        [aspectRatio, height, images, defaultAltText],
    )

    return (
        <CarouselContainer borderRadius={borderRadius}>
            {!!images && (
                <StyledCarouselProvider
                    naturalSlideWidth={naturalSlideWidth}
                    naturalSlideHeight={naturalSlideHeight}
                    totalSlides={numberOfImages}
                    visibleSlides={visibleSlides}
                    isIntrinsicHeight
                    step={step}
                    lockOnWindowScroll
                    dragEnabled={dragEnabled}
                >
                    <CarouselButtonBack />
                    <ImageContainer>
                        <StyledSlider $transitionTime={transitionTime}>{sliderImages}</StyledSlider>
                        {numberOfImages > 1 && <DotsContainer numberOfDots={numberOfImages} />}
                    </ImageContainer>
                    <CarouselButtonNext numberOfSlides={numberOfImages} />
                </StyledCarouselProvider>
            )}
        </CarouselContainer>
    )
}

export default ImageCarousel
