import React, { FC, Fragment, memo, useCallback } from 'react'

import { CarouselTitle, TextWrapper } from './region-overview-styles'
import { CallbackFunction } from '../../common/types'

interface Props {
    title: string
    index: number
    goToSlide: CallbackFunction
    isSelected: boolean
}

const RegionOverviewHeader: FC<Props> = ({ index, title, goToSlide, isSelected }) => {
    const moveContentSlide = useCallback(() => goToSlide(index), [goToSlide, index])

    return (
        <Fragment>
            <CarouselTitle index={index} onClick={moveContentSlide}>
                <TextWrapper isBottomBorder={isSelected}>{title}</TextWrapper>
            </CarouselTitle>
        </Fragment>
    )
}

export default memo(RegionOverviewHeader)
