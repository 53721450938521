import React, { FC, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react'
import { ScreenContext } from 'react-components'
import { AnyStyledComponent } from 'styled-components'
import ReactMarkdown from 'react-markdown'

import {
    Arrow,
    Card,
    CarouselContainer,
    CarouselElement,
    CarouselNav,
    ContentWrapper,
    DescriptionStyles,
    Dot,
    DotsWrapper,
    NavIcon,
    QuestionWrapper,
} from './region-overview-styles'
import { CallbackFunction } from '../../common/types'
import ImageLoadingComponent from '../app-components/image-loading-component'
import ImageTransport from '../../transports/common/image-transport'

import ChevronRight from '../../assets/icons/chevron-right.svg'
import ChevronLeft from '../../assets/icons/chevron-left.svg'

const getImageContent = (
    regionSectionImages: ImageTransport[],
    prevClick: CallbackFunction,
    nextClick: CallbackFunction,
    currentSlide: number,
    setCurrentSlide: CallableFunction,
    isMobile: boolean,
    title: string,
) => {
    return (
        <Fragment>
            {regionSectionImages.length > 0 ? (
                <CarouselContainer>
                    {regionSectionImages.length > 1 && (
                        <Fragment>
                            <CarouselNav left onClick={prevClick}>
                                <NavIcon left as={ChevronLeft as AnyStyledComponent} />
                            </CarouselNav>
                            <CarouselNav right onClick={nextClick}>
                                <NavIcon right as={ChevronRight as AnyStyledComponent} />
                            </CarouselNav>
                        </Fragment>
                    )}
                    {regionSectionImages.map((regionSectionImage, index) => (
                        <CarouselElement key={regionSectionImage.url} selected={index === currentSlide}>
                            <ImageLoadingComponent
                                src={
                                    (isMobile && regionSectionImage.formats?.medium?.url) ||
                                    regionSectionImage.formats?.large?.url ||
                                    regionSectionImage.url
                                }
                                alt={regionSectionImage.alt || 'region-overview-image'}
                                objectFit={'cover'}
                            />
                        </CarouselElement>
                    ))}
                    {!isMobile && regionSectionImages.length > 1 && (
                        <DotsWrapper>
                            {regionSectionImages.map((_, index) => (
                                <Dot
                                    key={`dots_${index}`}
                                    isSelected={index === currentSlide}
                                    onClick={() => setCurrentSlide(index)}
                                >
                                    <Card>{title}</Card>
                                    <Arrow />
                                </Dot>
                            ))}
                        </DotsWrapper>
                    )}
                </CarouselContainer>
            ) : (
                ''
            )}
            <DescriptionStyles>
                {!!regionSectionImages[currentSlide]?.caption && (
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    <ReactMarkdown>{regionSectionImages[currentSlide]?.caption!}</ReactMarkdown>
                )}
            </DescriptionStyles>
        </Fragment>
    )
}

interface Props {
    title: string
    content?: string
    regionSectionImages: ImageTransport[]
}

const RegionOverviewType: FC<Props> = ({ title, content, regionSectionImages }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0)

    const prevClick = useCallback(() => {
        if (currentSlide === 0) {
            setCurrentSlide(regionSectionImages.length - 1)
        } else {
            setCurrentSlide(currentSlide - 1)
        }
    }, [currentSlide, regionSectionImages])

    const nextClick = useCallback(() => {
        if (currentSlide === regionSectionImages.length - 1) {
            setCurrentSlide(0)
        } else {
            setCurrentSlide(currentSlide + 1)
        }
    }, [currentSlide, regionSectionImages])

    const { isMobile } = useContext(ScreenContext)

    const regionSectionImageContent = useMemo(
        () =>
            getImageContent(regionSectionImages, prevClick, nextClick, currentSlide, setCurrentSlide, isMobile, title),
        [regionSectionImages, prevClick, nextClick, currentSlide, setCurrentSlide, isMobile, title],
    )

    return (
        <Fragment>
            <Fragment>
                <QuestionWrapper>{title}</QuestionWrapper>
                {!!content && (
                    <ContentWrapper>
                        <ReactMarkdown>{content}</ReactMarkdown>
                    </ContentWrapper>
                )}

                {regionSectionImageContent}
            </Fragment>
        </Fragment>
    )
}

export default memo(RegionOverviewType)
