import React, { FC, memo, useContext, useEffect, useState } from 'react'
import { ScreenContext } from 'react-components'
import { AnyStyledComponent } from 'styled-components'

import {
    CarouselContainer,
    Container,
    LinkContainer,
    MainContainer,
    Star,
    StarSmall,
    StyledCarouselProvider,
    StyledSlider,
    Subtitle,
    SubtitleContainer,
    Title,
    TitleBubble,
    TitleContainer,
} from '../top-offers-styles'
import ListingSectionTransport from '../../../transports/common/listing-section-transport'
import { useTranslation } from '../../../hooks/locale'
import ExternalLink from '../../common/external-link'
import CarouselButtonBack from '../reviews-section/carousel-button-back'
import CarouselButtonNext from '../reviews-section/carousel-button-next'
import PropertyCards from './property-cards'

import StarRed from '../../../assets/icons/star-red.svg'

type Props = {
    listingSection: ListingSectionTransport
    isLoading: boolean
    getListings: () => void
    url: string
    sectionId: string
    listingType?: string
}

const getSlides = (isMobile: boolean, isDesktop: boolean, isTablet: boolean, isWideScreen: boolean) => {
    if (isMobile) {
        return 1
    } else if (isTablet) {
        return 2
    } else if (isDesktop && !isWideScreen) {
        return 3
    } else {
        return 4
    }
}

const ListingSection: FC<Props> = ({ listingSection, isLoading, getListings, url, listingType, sectionId }) => {
    const { heading, subheading, cta, objects: listings, totalCount: finalTotalListingCount } = listingSection
    const { t } = useTranslation()
    const [stopSliderTransition, setStopSliderTransition] = useState(isLoading)

    const { isMobile, isDesktop, isTablet, isWideScreen } = useContext(ScreenContext)
    const slides = getSlides(isMobile, isDesktop, isTablet, isWideScreen)

    useEffect(() => {
        setTimeout(function () {
            setStopSliderTransition(isLoading)
        }, 500)
    }, [isLoading])

    return (
        <MainContainer>
            <Container id={sectionId}>
                {listings.length > 0 && (
                    <>
                        <TitleContainer>
                            <Title>{heading}</Title>
                            {!isMobile && !!listingType && listingType === 'RED' && (
                                <TitleBubble>
                                    <Star as={StarRed as AnyStyledComponent} />
                                    <StarSmall as={StarRed as AnyStyledComponent} />
                                    {t('offerFeatureText')}
                                </TitleBubble>
                            )}
                            {!(listingType === 'RED') && (
                                <ExternalLink
                                    as={LinkContainer}
                                    title="See All"
                                    href={`${t('traumBaseUrl')}${cta?.url}`}
                                    noCrawl={cta?.noCrawl}
                                >
                                    {t('seeAll')}
                                </ExternalLink>
                            )}
                        </TitleContainer>

                        {!isMobile && (
                            <SubtitleContainer>
                                {!!subheading && !!finalTotalListingCount && (
                                    <Subtitle>{subheading.replace(/x/g, finalTotalListingCount.toString())}</Subtitle>
                                )}
                                {!isMobile && listingType === 'RED' && (
                                    <ExternalLink
                                        as={LinkContainer}
                                        title="See all"
                                        href={`${t('traumBaseUrl')}${cta?.url}`}
                                        noCrawl={cta?.noCrawl}
                                    >
                                        {t('seeAll')}
                                    </ExternalLink>
                                )}
                            </SubtitleContainer>
                        )}
                        <CarouselContainer>
                            <StyledCarouselProvider
                                totalSlides={listings.length}
                                visibleSlides={slides}
                                naturalSlideWidth={1}
                                naturalSlideHeight={1}
                                isIntrinsicHeight
                                lockOnWindowScroll
                                dragStep={1}
                                step={slides}
                                $customWidth={listings.length < slides}
                            >
                                <CarouselButtonBack />
                                <StyledSlider $stopSliderTransition={stopSliderTransition}>
                                    <PropertyCards
                                        listings={listings}
                                        isLoading={isLoading}
                                        url={url}
                                        listingType={listingType}
                                        visibleSlides={slides}
                                        loadMoreListings={getListings}
                                        finalTotalListingCount={finalTotalListingCount}
                                    />
                                </StyledSlider>
                                <CarouselButtonNext
                                    numberOfSlides={listings.length}
                                    visibleSlides={slides}
                                    isLoading={stopSliderTransition}
                                />
                            </StyledCarouselProvider>
                        </CarouselContainer>
                    </>
                )}
            </Container>
        </MainContainer>
    )
}

export default memo(ListingSection)
