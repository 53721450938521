import styled, { AnyStyledComponent, css } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { ChevronLeftIcon, ChevronRightIcon } from '../common/icons'
import Logo from '../../assets/logo/tfw-logo-symbol.svg'
import { ButtonBack, ButtonNext, CarouselProvider, Dot, DotProps, Slide, Slider } from 'pure-react-carousel'

export const Container = styled.section`
    padding: 0 ${({ theme }) => theme.spacing.medium} 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${({ theme }) => theme.spacing.large} 0;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.large};
        margin: calc(${props => props.theme.spacing.xxxLarge} + ${props => props.theme.spacing.small}) 0 0;
    }
`

export const SectionContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    box-sizing: border-box;
`

export const ImageContainer = styled.div`
    width: ${({ theme }) => theme.layout.fill};
    height: 12.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 18.75rem;
    }
`

export const CardContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    border: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
    margin-right: ${props => props.theme.spacing.xSmall};
    height: ${({ theme }) => theme.layout.fill};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: ${props => props.theme.spacing.medium};
        width: calc(100% - 16px);
    }
    > div:first-child {
        height: ${({ theme }) => theme.layout.fill};
    }
`

export const ContentContainer = styled.div`
    margin: ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.small};
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    justify-content: ${props => props.theme.style.justifyContent.center};
    align-items: ${props => props.theme.style.alignItems.flexStart};
    flex-grow: 1;
`

export const Title = styled.h2`
    margin: 0;
    font-family: ${props => props.theme.typography.font.family.yummoVerdana};
    text-align: ${props => props.theme.style.textAlign.center};
    font-size: ${props => props.theme.typography.font.size.large};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    line-height: 1.75rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    letter-spacing: -0.021875rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: calc(
            ${props => props.theme.typography.font.size.medium} + ${props => props.theme.typography.font.size.large}
        );
        line-height: 2.625rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        text-align: ${props => props.theme.style.textAlign.left};
        font-weight: ${props => props.theme.typography.font.weight.bold};
        margin-bottom: ${props => props.theme.spacing.xxSmall};
    }
`

export const Subtitle = styled.h3`
    margin: 0 0 ${props => props.theme.spacing.medium};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    text-align: ${props => props.theme.style.textAlign.left};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    line-height: normal;
    color: ${({ theme }) => theme.colors.tfwBlackOpacity50};
    letter-spacing: 0.0125rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.large};
        line-height: 1.75rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        text-align: ${props => props.theme.style.textAlign.left};
        letter-spacing: 0.021875rem;
    }
`

export const MarkdownOuterContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    flex-grow: 1;
    & > p > strong {
        text-decoration: underline;
    }
`

export const MarkdownTextContainer = styled.div`
    height: 6rem;
    text-align: ${props => props.theme.style.textAlign.left};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    overflow: hidden;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
    margin-top: -${props => props.theme.spacing.xSmall};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    & > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: 500;
    }
    & > p > strong {
        text-decoration: underline;
    }
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        height: unset;
        margin-bottom: ${props => props.theme.spacing.small};
    }
`

export const ShowMoreText = styled.div`
    text-align: ${props => props.theme.style.textAlign.center};
    text-decoration: underline;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-size: ${props => props.theme.typography.font.size.small};
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    line-height: 1.5;
`

export const ButtonContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    width: ${({ theme }) => theme.layout.fill};
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.font.size.small};
    text-align: ${({ theme }) => theme.style.textAlign.center};
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const OverLayContainer = styled.div`
    position: ${props => props.theme.style.position.fixed};
    background-color: ${props => props.theme.colors.tfwBlackOpacity50};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    justify-content: ${props => props.theme.style.justifyContent.flexEnd};
    box-shadow: 0 0 8px 0 ${props => props.theme.colors.tfwBlackOpacity50};
`

export const OverlayOuterContentContainer = styled.div`
    flex-grow: 0;
    overflow: auto;
    background-color: ${props => props.theme.colors.white};
    border-radius: ${props => props.theme.typography.border.radius.xLarge}
        ${props => props.theme.typography.border.radius.xLarge} 0 0;
`

export const OverlayContentContainer = styled.div`
    padding: ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.medium};
`

export const OverlayHeadSection = styled.div`
    display: ${props => props.theme.style.display.flex};
`

export const OverlayHeading = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    margin-top: ${props => props.theme.spacing.medium};
`

export const OverlayTitle = styled.h2`
    margin: 0;
    font-family: ${props => props.theme.typography.font.family.yummoVerdana};
    font-size: ${props => props.theme.typography.font.size.large};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    line-height: 1.75rem;
    letter-spacing: -0.021875rem;
`

export const OverlaySubTitle = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    letter-spacing: 0.0125rem;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
`

export const OverlayMarkdownContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    overflow: hidden;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: 0.875rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    & > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: 500;
    }
`

export const CrossButtonContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    top: 0.375rem;
    right: 0.375rem;
    width: 2.375rem;
    height: 2.375rem;
    background: rgba(255, 255, 255, 0.78);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.625rem;
    margin: ${props => props.theme.spacing.xxSmall} 0 0 ${props => props.theme.spacing.medium};
`

export const CrossIcon = styled.img`
    height: 1.25rem;
    width: 1.25rem;
`

export const LogoContainer = styled.div`
    position: ${props => props.theme.style.position.absolute};
    display: ${props => props.theme.style.display.flex};
    background-color: ${props => props.theme.colors.white};
    align-items: center;
    left: ${props => props.theme.spacing.xSmall};
    top: ${props => props.theme.spacing.xSmall};
    z-index: 10;
    padding: calc(${props => props.theme.spacing.xxSmall} + ${props => props.theme.spacing.xxxSmall})
        ${props => props.theme.spacing.xSmall};
    border-radius: ${props => props.theme.typography.border.radius.xSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        left: ${props => props.theme.spacing.medium};
        top: ${props => props.theme.spacing.medium};
        padding: ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.small};
        border-radius: ${props => props.theme.typography.border.radius.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        left: ${props => props.theme.spacing.large};
        top: ${props => props.theme.spacing.large};
    }
`

export const LogoImage = styled(Logo as AnyStyledComponent)`
    height: ${props => props.theme.layout.xxSmall};
    width: ${props => props.theme.layout.xxSmall};
    margin-right: calc(5 * ${props => props.theme.spacing.xxxSmall});
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: ${props => props.theme.layout.xSmall};
        width: ${props => props.theme.layout.xSmall};
    }
`

export const LogoText = styled.div`
    border-radius: 1.25rem;
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.xSmall};
    line-height: 1rem;
    letter-spacing: 0.0125rem;
    color: ${props => props.theme.colors.tfwBlack87};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-family: ${props => props.theme.typography.font.family.yummo};
        font-size: ${props => props.theme.typography.font.size.large};
        font-weight: ${props => props.theme.typography.font.yummo.weight.bold};
        line-height: 1.75rem;
        letter-spacing: -0.021875rem;
    }
`

export const DesktopContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    min-height: 41.5rem;
    max-width: calc(8 * ${({ theme }) => theme.layout.xxLarge});
    margin: 0 auto;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        min-height: 31.25rem;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        max-width: none;
        margin: 0;
    }
`
export const TipsSectionHeading = styled.h2`
    margin: ${({ theme }) => theme.spacing.xxLarge} ${({ theme }) => theme.spacing.medium}
        ${({ theme }) => theme.spacing.medium} 0;
    font-size: ${({ theme }) => theme.typography.font.size.large};
    font-weight: 700;
    font-family: ${({ theme }) => theme.typography.font.family.yummo};
    line-height: 1.75rem;
    letter-spacing: -0.35px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        line-height: 2.625rem;
        font-size: 2.25rem;
        letter-spacing: -1px;
        margin: ${({ theme }) => theme.spacing.xxLarge} 0 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 3.75rem 0 0.5rem;
    }
`
export const TipsSectionSubHeading = styled.p`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-family: ${props => props.theme.typography.font.family.lato};
        font-weight: ${props => props.theme.typography.font.weight.normal};
        font-size: ${props => props.theme.typography.font.size.medium};
        line-height: 1.5rem;
        color: #00000080;
        margin-bottom: ${props => props.theme.spacing.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0 0 ${props => props.theme.spacing.large};
    }
`

export const InnerDesktopContainer = styled.div`
    height: ${props => props.theme.layout.fill};
    position: ${props => props.theme.style.position.relative};
    border: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} #00000019;
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
`

export const DesktopCardContainer = styled.div`
    width: ${props => props.theme.layout.fill};
    display: ${props => props.theme.style.display.flex};
    flex-wrap: wrap;
    min-height: 41.5rem;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        min-height: 31.25rem;
    }
`

export const TipsContent = styled.div`
    padding: ${props => props.theme.spacing.xLarge};
    flex-basis: 50%;
    max-width: 50%;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 59%;
        flex-basis: 59%;
    }
`

export const ImageDesktopContainer = styled.div`
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    position: ${props => props.theme.style.position.relative};
    padding: 0 0.625rem;
    height: inherit;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
        max-width: 40.546875%;
        flex-basis: 40.546875%;
    }
`

export const DesktopMarkdownText = styled.div`
    margin-top: 0.3125rem;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    font-size: ${props => props.theme.typography.font.size.medium};
    font-weight: 300;
    line-height: 1.6;
    color: ${props => props.theme.colors.tfwBlack87};
    & > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: 500;
    }
    & > p > strong {
        text-decoration: underline;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: ${props => props.theme.spacing.large} 0;
        font-weight: ${props => props.theme.typography.font.weight.normal};
        line-height: 1.5rem;
    }
`

export const DesktopButtonContainer = styled.div`
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    text-align: ${props => props.theme.style.textAlign.center};
    font-size: ${props => props.theme.typography.font.size.medium};
    color: ${props => props.theme.colors.tfwBlack87};
    font-weight: 400;
    line-height: normal;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: fit-content;
        font-weight: ${props => props.theme.typography.font.weight.bold};
        line-height: 1.5rem;
        font-size: ${props => props.theme.typography.font.size.small};
    }
`

export const SliderContainer = styled.div`
    margin-top: 2.5rem;
`

export const CustomDot = styled(Dot)<DotProps & { selected: boolean }>`
    height: 0.875rem;
    width: 0.875rem;
    border: 1px solid ${props => props.theme.colors.tfwBlack87};
    background-color: ${props => (props.selected ? props.theme.colors.tfwBlack87 : props.theme.colors.white)};
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.625rem;
    cursor: pointer;
`

export const ArrowStyles = ({}) => css`
    position: ${props => props.theme.style.position.absolute};
    top: 42%;
    z-index: 1;
    cursor: pointer;
    display: ${props => props.theme.style.display.flex};
    align-items: ${props => props.theme.style.alignItems.center};
    justify-content: ${props => props.theme.style.justifyContent.center};
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.75);
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        background: ${props => props.theme.colors.white};
        border: ${props => props.theme.typography.border.radius.xxxSmall}
            ${props => props.theme.typography.border.style.solid} #00000019;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.06), 0px 3px 14px rgba(0, 0, 0, 0.04), 0px 5px 5px rgba(0, 0, 0, 0.08);
    }
`

export const ArrowContainerBack = styled(ButtonBack)`
    ${ArrowStyles};
    left: -1.5rem;
`

export const ArrowContainerNext = styled(ButtonNext)`
    ${ArrowStyles};
    right: -1.5rem;
`

export const ArrowLeftIcon = styled(ChevronLeftIcon)`
    height: 1.625rem;
    width: 1.625rem;
`

export const ArrowRightIcon = styled(ChevronRightIcon)`
    height: 1.625rem;
    width: 1.625rem;
`

export const StyledCarouselProvider = styled(CarouselProvider)`
    width: 100%;
    li {
        list-style: none;
    }
`

export const StyledSlider = styled(Slider)`
    padding: 0;
    ul {
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        will-change: transform;
    }

    & > .carousel__slider-tray-wrapper {
        overflow: hidden;
    }
`

export const StyledSlide = styled(Slide)`
    > div:first-child {
        height: ${({ theme }) => theme.layout.fill};
    }
`
