import React, { FC, Fragment, memo, useState } from 'react'

import {
    InnerContainer,
    InnerSection,
    NewsLetterAnchor,
    NewsLetterHeading,
    NewsLetterSubheading,
    NewsLetterWrapper,
    OuterSection,
} from './news-letter-style'
import NewsletterInput from './news-letter-form'
import PopUpRegisterAlert from './pop-up-register-alert'
import { NewsletterSectionTransport } from '../../transports/region-landing-page-transport'
import NextImage from '../common/next-image'

interface NewsLetterProps {
    newsletter: NewsletterSectionTransport
    url: string
}

const NewsLetter: FC<NewsLetterProps> = ({ newsletter, url }) => {
    const {
        heading,
        subheading,
        policyDescription,
        placeholder,
        newsLetterImage,
        collapsiblePolicy,
        policyLinkButton,
        subscribeButton,
        readLessLabel,
        readMoreLabel,
        successAlert,
    } = newsletter

    const [alertVisible, setAlertVisible] = useState<boolean>(false)
    return (
        <Fragment>
            <PopUpRegisterAlert text={successAlert} visible={alertVisible} setAlertVisible={setAlertVisible} />
            <OuterSection>
                <InnerContainer>
                    <NextImage src={newsLetterImage.url} alt={'newsletter'} />
                    <InnerSection>
                        <NewsLetterAnchor id="newsletter" />
                        <NewsLetterWrapper>
                            <NewsLetterHeading>{heading}</NewsLetterHeading>
                            <NewsLetterSubheading>{subheading}</NewsLetterSubheading>
                            <NewsletterInput
                                placeholder={placeholder}
                                subscribeButtonText={subscribeButton.text}
                                policyDescription={policyDescription}
                                collapsiblePolicyText={collapsiblePolicy}
                                policyLinkButton={policyLinkButton}
                                setAlertVisible={setAlertVisible}
                                readMoreLabel={readMoreLabel}
                                readLessLabel={readLessLabel}
                                url={url}
                            />
                        </NewsLetterWrapper>
                    </InnerSection>
                </InnerContainer>
            </OuterSection>
        </Fragment>
    )
}

export default memo(NewsLetter)
