import styled, { css } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

export const SectionContainer = styled.section`
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.large};
    }
`
export const Container = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    font-size: ${({ theme }) => theme.typography.font.size.xSmall};
    line-height: 1.5rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    padding: 1rem 0 0;
    margin: 0 1rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.small};
        border: ${({ theme }) => theme.typography.border.radius.xxxSmall} solid
            ${({ theme }) => theme.colors.tfwBlackOpacity10};
        border-radius: ${({ theme }) => theme.typography.border.radius.xLarge};
        margin: 0;
        padding-top: ${({ theme }) => theme.spacing.medium};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
        padding-top: ${({ theme }) => theme.spacing.large};
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        width: 100%;
    }
`

export const Title = styled.h2`
    font-family: ${({ theme }) => theme.typography.font.family.yummo};
    font-weight: ${({ theme }) => theme.typography.font.yummo.weight.bold};
    font-size: 1.25rem;
    margin: 2.5rem 0 0 1rem;
    color: ${props => props.theme.colors.tfwBlack87};
    letter-spacing: -0.35px;
    line-height: 1.75rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 2.25rem;
        font-weight: ${({ theme }) => theme.typography.font.yummo.weight.bold};
        margin-bottom: 1rem;
        margin-left: 0;
        letter-spacing: -1px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: 1.5rem;
        margin-top: 3.75rem;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-left: 0;
    }
`

interface TitleProps {
    isExpanded?: boolean
    isBottomBorder?: boolean
}

export const ArrowContainer = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    top: 30%;
    display: none;
    &:before {
        display: none;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: flex;
    }
`

export const HeaderContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.tfwBlackOpacity10};
    padding: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 1rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`
interface CarouselTitleProps {
    index: number
}

export const CarouselTitle = styled.div<CarouselTitleProps>`
    cursor: pointer;
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: column;
    width: fit-content;
    min-width: fit-content;
    margin-right: ${({ theme }) => theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: ${props => (props.index === 0 ? '1.625rem' : props.theme.spacing.large)};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-right: ${props => (props.index === 0 ? '2.5625rem' : props.theme.spacing.xLarge)};
    }
`

export const FaqsWrapper = styled.div`
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    line-height: 1.5rem;
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    padding-bottom: 1.25rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-bottom: ${({ theme }) => theme.spacing.large};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-top: 0.0625rem;
        font-size: ${({ theme }) => theme.typography.font.size.medium};
        padding-bottom: ${({ theme }) => theme.spacing.large};
    }
`

export const TextWrapper = styled.h3<TitleProps>`
    margin: 0;
    flex: 1;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    padding-bottom: ${({ theme }) => theme.spacing.small};
    font-size: ${({ theme }) => theme.typography.font.size.small};
    ${({ isBottomBorder }) => isBottomBorder && 'border-bottom: 0.25rem solid #2298da'};
    ${({ isBottomBorder }) => isBottomBorder && 'color: #2298da'};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-bottom: 1.25rem;
    }
`

export const HeaderArrow = styled.img`
    height: 1.5rem;
    width: 1.5rem;
`

export const ContentWrapper = styled.div`
    padding: 0;
    text-align: justify;
    font-weight: 400;
    margin-bottom: 1.25rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: ${({ theme }) => theme.spacing.large};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
        margin-bottom: ${({ theme }) => theme.spacing.large};
    }
`

export const QAContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 1rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 1.5rem;
    }
`

interface QAContentProps {
    active: boolean
}

export const QAContent = styled.div<QAContentProps>(
    ({ theme, active }) => css`
        position: ${active ? theme.style.position.relative : theme.style.position.absolute};
        z-index: ${active ? 10 : -1};
        opacity: ${active ? 1 : 0};
        transition: 0.3s opacity ease-in, 0.6s transform ease;
    `,
)

export const QuestionWrapper = styled.h4`
    font-size: ${({ theme }) => theme.typography.font.size.small};
    padding: 0;
    font-weight: 700;
    margin: 0.75rem 0 0.25rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 1.5rem 0 0.25rem;
    }
`

export const AnswerWrapper = styled.p`
    font-size: ${({ theme }) => theme.typography.font.size.small};
    padding: 0;
    font-weight: 400;
    margin: 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`

export const CarouselContainer = styled.div`
    position: ${({ theme }) => theme.style.position.relative};
    height: calc(100vh - 10rem);
    overflow: hidden;
`

export const CarouselElement = styled.div<{ selected: boolean }>`
    position: ${({ theme }) => theme.style.position.absolute};
    height: 100%;
    width: 100%;

    transition: opacity 200ms ease-out;
    opacity: ${props => (props.selected ? '1' : '0')};
`

export const CarouselNav = styled.div<{ left?: boolean; right?: boolean }>`
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    transform: translateY(-50%);
    background: #ffffffc0;
    border: none;
    cursor: pointer;
    ${props => props.left && 'left: 0.625rem;'}
    ${props => props.right && 'right: 0.625rem;'}

    :hover {
        background: #ffffffe0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        ${props => props.left && 'left: 1.25rem;'}
        ${props => props.right && 'right: 1.25rem;'}
    }
`

export const NavIcon = styled.img<{ left?: boolean; right?: boolean }>`
    position: absolute;
    top: 20%;
    width: 60%;
    height: 60%;
    ${props => props.left && 'left: 20%'}
    ${props => props.right && 'right: 20%'}
`

export const DescriptionStyles = styled.div`
    font-weight: 400;
    padding: 0 0.625rem;
    margin-bottom: 1.25rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: ${({ theme }) => theme.spacing.large};
    }
`

export const DotsWrapper = styled.ul`
    position: absolute;
    display: flex;
    z-index: 5;
    left: 1.25rem;
    bottom: 0;
    margin: 0;
    padding: 1.25rem 0;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        left: 50%;
        transform: translate(-50%);
    }
`

interface DotProps {
    isSelected: boolean
}

export const Dot = styled.div<DotProps>`
    display: ${({ theme }) => theme.style.display.flex};
    height: 0.625rem;
    width: 0.625rem;
    margin: 0 0.25rem;
    border: 0.0625rem solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    box-shadow: 0 0.5rem 0.625rem 0.0625rem #21212123, 0 0.1875rem 0.875rem 0.1875rem #2121210f,
        0 0.25rem 0.625rem 0 #21212119;

    ${props => props.isSelected && `background-color: ${props.theme.colors.white}`};
`

export const Card = styled.div`
    position: ${({ theme }) => theme.style.position.absolute};
    display: none;
    height: 2.5rem;
    bottom: 2.5rem;
    white-space: nowrap;
    align-items: ${({ theme }) => theme.style.alignItems.center};
    font-family: ${({ theme }) => theme.typography.font.family.latoVerdana};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    font-weight: 300;
    font-size: 0.875rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 1rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    box-shadow: 0 0.5rem 0.625rem 0.0625rem #21212123, 0 0.1875rem 0.875rem 0.1875rem #2121210f,
        0 0.25rem 0.625rem 0 #21212119;

    ${Dot}:hover & {
        display: ${({ theme }) => theme.style.display.flex};
    }
`

export const Arrow = styled.div`
    position: ${({ theme }) => theme.style.position.absolute};
    display: none;
    bottom: 2.25rem;
    height: 0.5rem;
    width: 0.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    transform: rotate(45deg);

    ${Dot}:hover & {
        display: ${({ theme }) => theme.style.display.flex};
    }
`

export const RegionSlider = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    padding: 0;
    position: ${({ theme }) => theme.style.position.relative};
`

export const RegionContainerForSlider = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    overflow-x: scroll;
    white-space: nowrap;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-left: ${({ theme }) => theme.spacing.large};
        margin-right: ${({ theme }) => theme.spacing.large};
    }
    /* this property needed specific browser setting, as there is no general property which serves the purpose */
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
`

interface Arrowtype {
    arrowType: string
}

export const RegionArrow = styled.div<Arrowtype>`
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    position: ${({ theme }) => theme.style.position.absolute};
    top: 0;
    bottom: 0;
    left: ${props => (props.arrowType === 'left' ? '0' : '')};
    right: ${props => (props.arrowType === 'right' ? '0' : '')};
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    @media only screen and (max-width: ${tabletBreakpoint.max}px) {
        display: ${({ theme }) => theme.style.display.none};
    }
`
