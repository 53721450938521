import styled from 'styled-components'
import { SolidButton } from 'react-components'

interface MainContainerProps {
    visible: boolean
}

export const MainContainer = styled.section<MainContainerProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    /* Conditionally set styles based on the visible prop */
    transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(100%)')};
    opacity: ${props => (props.visible ? '1' : '0')};
`

export const CtaContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    display: ${props => props.theme.style.display.flex};
    justify-content: ${props => props.theme.style.justifyContent.center};
    width: 100%;
    max-width: 480px;
    padding: 16px;
`

export const CtaButtonText = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
    font-feature-settings: 'liga' off;
    color: ${props => props.theme.colors.tfwBlack87};
`

export const StickyCtaButton = styled(SolidButton).attrs({ padding: '8px 16px', width: '100%' })``
