import styled, { css } from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

export const AnchorStyles = styled.a`
    width: 100%;
    text-decoration: none;
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    color: ${props => props.theme.colors.tfwBlack87};
    font-size: ${props => props.theme.typography.font.size.medium};
    cursor: pointer;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 50%;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 25%;
    }
`

export const Box = styled.div`
    display: ${props => props.theme.style.display.flex};
    padding: 0.375rem 0 1rem;
    margin-top: 0.625rem;
    line-height: 1.6;
    align-items: ${props => props.theme.style.alignItems.center};
    border-bottom: ${props => props.theme.typography.border.width.xxxThin} solid
        ${props => props.theme.colors.tfwBlackOpacity10};
    transition: padding 200ms ease-out;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0.375rem 0.625rem 1rem;
    }

    &:hover {
        padding: 0.375rem 0 1rem;
        border-bottom-color: ${props => props.theme.colors.tfwBlack87};

        &:before {
            transition: left 200ms ease-out, right 200ms ease-out;
            left: 0;
            right: 0;
        }
    }

    ${props =>
        props.hidden &&
        css`
            display: none;
        `}
`

export const SpanStyles = styled.span`
    width: ${props => props.theme.layout.fill};
`

export const RightIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
`
export const RegionName = styled.div`
    width: ${props => props.theme.layout.fill};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    white-space: nowrap;
    overflow: ${props => props.theme.style.overflow.hidden};
    text-overflow: ellipsis;
    line-height: 1.5rem;
`
