import styled, { keyframes } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint } from 'react-components'

const bounceOutUp = keyframes`
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
`

const bounceInDown = keyframes`
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    75% {
        transform: translate3d(0, -5px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
`

interface Props {
    isClosed: boolean
}

export const Container = styled.div`
    position: fixed;
    width: 100%;
    top: 3.25rem;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0.625rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 90%;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 38.75rem;
    }
`

export const InnerContainer = styled.div<Props>`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    margin: 0.25rem 0;
    overflow: hidden;
    border-radius: 0.375rem;
    position: relative;
    box-shadow: 0 0.375rem 0.625rem 0 rgba(33, 33, 33, 0.14), 0 0.0625rem 1.125rem 0 rgba(33, 33, 33, 0.06),
        0 0.1875rem 0.625rem 0 rgba(33, 33, 33, 0.1);
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.feedbackGreen};
    animation-name: ${({ isClosed }) => (isClosed ? bounceOutUp : bounceInDown)};
    animation-duration: 1s;
    animation-fill-mode: both;
`

export const TextContainer = styled.div`
    font-style: normal;
    padding: 1.25rem;
    font-size: ${props => props.theme.typography.font.size.medium};
    font-weight: bold;
`

export const CloseIcon = styled.div`
    position: absolute;
    top: 0.125rem;
    right: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-weight: bold;
    font-size: ${props => props.theme.typography.font.size.small};
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.125rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    fill: #fff;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
    }
`
