import React, { FC, memo, useCallback, useContext } from 'react'
import { AnyStyledComponent, ThemeContext } from 'styled-components'

import VerticalCardImageContainer from '../../../object-cards/vertical-card-components/vertical-card-image-container'
import VerticalCardTopRight from '../../../object-cards/vertical-card-components/vertical-card-top-right'
import VerticalCardTopLeft from '../../../object-cards/vertical-card-components/vertical-card-top-left'
import {
    PropertyContextContainer,
    FooterInnerWrapper,
    GuestText,
    NoDiscountPriceText,
    PriceText,
    RatingsContainer,
    RatingsIcon,
    RatingsText,
    RedPill,
    RegionName,
    RegionPinIcon,
    RegionNameText,
    Title,
    VerticalSeparator,
    StyledGhostButton,
    ButtonText,
    CardFooter,
    CardContainer,
    FavouriteButtonContainer,
    SeoHref,
} from './vertical-property-card-styles'
import { ObjectTopRecommendationPill } from '../../top-recommendations-section/styles'
import { ListingSectionObject } from '../../../../transports/common/listing-section-transport'
import VerticalCardBodyTop from '../../../object-cards/vertical-card-components/vertical-card-body-top'
import { useTranslation } from '../../../../hooks/locale'
import VerticalCardBodyBottom from '../../../object-cards/vertical-card-components/vertical-card-body-bottom'
import PropertyStatsContainer from './property-stats-container'
import VerticalCard from '../../../object-cards/vertical-card'
import WishlistSection from '../../../object-cards/wishlist-section'
import ImageCarousel from './image-carousel'
import ImageLoadingComponent from '../../../app-components/image-loading-component'
import { usePropertyCardTracking } from '../../hooks'

import Star from '../../../../assets/icons/star.svg'
import { preventDefaultHandler } from '../../../utils'
import { getImageAltText } from '../../utils'

const MAX_RATING = 5

interface Props {
    listingObject: ListingSectionObject
    listingType?: string | null
    transitionTime: number
    dragEnabled: boolean
    naturalSlideHeight: number
    naturalSlideWidth: number
    visibleSlides: number
    step: number
    handleCardContentClick: CallableFunction
    imageAspectRatio?: string
    position: number
}

const VerticalPropertyCard: FC<Props> = ({
    listingObject,
    listingType,
    transitionTime,
    dragEnabled,
    naturalSlideHeight,
    naturalSlideWidth,
    visibleSlides,
    step,
    handleCardContentClick,
    imageAspectRatio,
    position,
}) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)

    const {
        offer,
        seoRegion,
        rating,
        title,
        stats,
        price,
        noDiscountPrice,
        apartment,
        id: listingId,
        isTopObject,
        breadcrumbs,
        href,
        seoHref,
    } = listingObject

    const images = apartment?.images || listingObject.images
    const propertyTitle = title || apartment?.title
    const formattedPrice = price.isPriceNumeric && `${price.persons} / ${price.unit}`

    const { intersectionTargetRef } = usePropertyCardTracking(listingObject, listingType, position)

    const getDefaultAltText = useCallback(() => {
        return getImageAltText(listingObject)
    }, [listingObject])

    return (
        <>
            <CardContainer ref={intersectionTargetRef}>
                <VerticalCard onCardClick={handleCardContentClick} snippetPadding={'0.75rem 1rem 1rem'}>
                    <VerticalCardTopLeft>
                        {offer && (
                            <RedPill>
                                {offer.discount} ● {offer.label}
                            </RedPill>
                        )}
                    </VerticalCardTopLeft>
                    <VerticalCardTopRight>
                        <FavouriteButtonContainer>
                            <WishlistSection
                                containerBackgroundColor={theme.colors.white}
                                containerBorderRadius={theme.typography.border.radius.xLarge}
                                containerHeight={theme.layout.xSmall}
                                containerWidth={theme.layout.xSmall}
                                containerMargin={'0'}
                                containerPadding={theme.spacing.xxSmall}
                                width={theme.layout.xxSmall}
                                height={theme.layout.xxSmall}
                                listingId={listingId.toString()}
                            />
                        </FavouriteButtonContainer>
                    </VerticalCardTopRight>
                    {images.length > 0 && (
                        <VerticalCardImageContainer>
                            {images.length === 1 ? (
                                <ImageLoadingComponent
                                    src={images[0].url}
                                    alt={!!images[0].alt ? images[0].alt : `${getDefaultAltText()} - image1`}
                                    paddingTop={imageAspectRatio}
                                    borderRadius={'12px 12px 0 0'}
                                />
                            ) : (
                                <ImageCarousel
                                    images={images}
                                    transitionTime={transitionTime}
                                    dragEnabled={dragEnabled}
                                    naturalSlideHeight={naturalSlideHeight}
                                    naturalSlideWidth={naturalSlideWidth}
                                    visibleSlides={visibleSlides}
                                    step={step}
                                    aspectRatio={imageAspectRatio}
                                    defaultAltText={getDefaultAltText()}
                                />
                            )}
                            {!!isTopObject && (
                                <ObjectTopRecommendationPill>{t('topListing')}</ObjectTopRecommendationPill>
                            )}
                        </VerticalCardImageContainer>
                    )}
                    <VerticalCardBodyTop>
                        <PropertyContextContainer>
                            <RegionName>
                                <RegionPinIcon />
                                <RegionNameText onClick={e => e.stopPropagation()}>
                                    {breadcrumbs ? (
                                        <SeoHref
                                            title={breadcrumbs[breadcrumbs.length - 1].name}
                                            href={breadcrumbs[breadcrumbs.length - 1].url}
                                        >
                                            {breadcrumbs[breadcrumbs.length - 1].name}
                                        </SeoHref>
                                    ) : (
                                        <SeoHref
                                            title={seoRegion.name}
                                            href={seoRegion.url.url}
                                            noCrawl={seoRegion.url.noCrawl}
                                        >
                                            {seoRegion.name}
                                        </SeoHref>
                                    )}
                                </RegionNameText>
                            </RegionName>
                            {rating.count !== 0 ? (
                                <>
                                    <VerticalSeparator />
                                    <RatingsContainer>
                                        <RatingsIcon as={Star as AnyStyledComponent} />
                                        <RatingsText>
                                            {`${rating.average}/${MAX_RATING}
                                                    (${rating.count} ${
                                                rating.count === 1 ? t('review') : t('reviews')
                                            })`}
                                        </RatingsText>
                                    </RatingsContainer>
                                </>
                            ) : null}
                        </PropertyContextContainer>
                        <Title>
                            <SeoHref
                                autoRedirect={false}
                                title={propertyTitle}
                                href={seoHref ?? href}
                                onClick={preventDefaultHandler}
                            >
                                {propertyTitle}
                            </SeoHref>
                        </Title>
                        <PropertyStatsContainer stats={stats} />
                    </VerticalCardBodyTop>
                    <VerticalCardBodyBottom>
                        <CardFooter>
                            <FooterInnerWrapper>
                                <PriceText hasPrice={!formattedPrice}>
                                    {price.price}{' '}
                                    {listingType && <NoDiscountPriceText>{noDiscountPrice?.price}</NoDiscountPriceText>}
                                </PriceText>
                                {!!formattedPrice && <GuestText>{formattedPrice}</GuestText>}
                            </FooterInnerWrapper>
                            <StyledGhostButton hoverEffect>
                                <ButtonText>{t('sliderCTA')}</ButtonText>
                            </StyledGhostButton>
                        </CardFooter>
                    </VerticalCardBodyBottom>
                </VerticalCard>
            </CardContainer>
        </>
    )
}

export default memo(VerticalPropertyCard)
