import React, { FC, memo } from 'react'
import { CarouselRightIcon, StyledButtonNext } from './styles'
import { useCurrentSlideIndex } from '../../../hooks/carousel'

interface Props {
    numberOfSlides: number
    visibleSlides: number
}

const CarouselButtonNext: FC<Props> = ({ numberOfSlides, visibleSlides }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return numberOfSlides - _currentSlideIndex > visibleSlides ? (
        <StyledButtonNext $right>
            <CarouselRightIcon />
        </StyledButtonNext>
    ) : null
}

export default memo(CarouselButtonNext)
