import React, { FC, memo } from 'react'

import { InnerWrapper, OuterSection, OuterWrapper, PopularFilterLink } from './sort-option-styles'
import SortOptionTransport from '../../transports/common/sort-option-transport'
import ExternalLink from '../common/external-link'

interface SortOptionProps {
    sortOptions: SortOptionTransport[]
}

const SortOption: FC<SortOptionProps> = ({ sortOptions }) => {
    return (
        <OuterWrapper>
            <InnerWrapper>
                <OuterSection>
                    {sortOptions.map(item => (
                        <ExternalLink as={PopularFilterLink} href={item.url} key={item.url} noCrawl={item.noCrawl}>
                            {item.label}
                        </ExternalLink>
                    ))}
                </OuterSection>
            </InnerWrapper>
        </OuterWrapper>
    )
}

export default memo(SortOption)
