import React, { FC, memo } from 'react'

import ListingSectionTransport from '../../transports/common/listing-section-transport'
import ListingSection from './listing-section'
import { usePopularFilterSection } from '../../hooks/listings'

interface Props {
    popularFilterListingsSection: ListingSectionTransport
    url: string
    sectionId: string
}

const PopularFilterListingsSection: FC<Props> = ({ popularFilterListingsSection, url, sectionId }) => {
    const { section, getPopularFilterListings, isLoading } = usePopularFilterSection(popularFilterListingsSection)
    return (
        <ListingSection
            listingSection={section}
            getListings={getPopularFilterListings}
            isLoading={isLoading}
            url={url}
            sectionId={sectionId}
            listingType={'POPULAR_FILTER_BASED'}
        />
    )
}

export default memo(PopularFilterListingsSection)
