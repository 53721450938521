import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { ScreenContext } from 'react-components'
import { ThemeContext } from 'styled-components'

import { ListingSectionObject } from '../../../transports/common/listing-section-transport'
import { CardContainer, CarouselContainer, FavouriteButtonContainer } from './styles'
import { ImageCarousel } from '../common/property-card/image-carousel'
import WishlistSection from '../../object-cards/wishlist-section'
import { getImageAltText } from '../utils'

interface Props {
    recommendedObject: ListingSectionObject
}

const ImageCarouselCard: FC<Props> = ({ recommendedObject }) => {
    const { isTablet } = useContext(ScreenContext)
    const theme = useContext(ThemeContext)

    const aspectRatio = useMemo(() => {
        return isTablet ? '80%' : '64.24%'
    }, [isTablet])

    const imageCardHeight = useMemo(() => {
        return isTablet ? '13rem' : '14.5625rem'
    }, [isTablet])

    const getDefaultAltText = useCallback(() => {
        return getImageAltText(recommendedObject)
    }, [recommendedObject])

    const borderRadius = '0.75rem'

    return (
        <CardContainer>
            <CarouselContainer>
                {/* {regionItem.pillType === 'PURPLE' && <PurplePill>{t(regionItem.type)}</PurplePill>}
                {regionItem.pillType === 'RED' && regionItem.offer && (
                    <RedPill>{`${regionItem.offer.discount}  ●  ${regionItem.offer.label}`}</RedPill>
                )} */}
                <FavouriteButtonContainer>
                    <WishlistSection
                        containerBackgroundColor={theme.colors.white}
                        containerBorderRadius={theme.typography.border.radius.xLarge}
                        containerHeight={theme.layout.xSmall}
                        containerWidth={theme.layout.xSmall}
                        containerMargin={'0'}
                        containerPadding={theme.spacing.xxSmall}
                        width={theme.layout.xxSmall}
                        height={theme.layout.xxSmall}
                        listingId={recommendedObject.id.toString()}
                    />
                </FavouriteButtonContainer>
                {!!recommendedObject?.apartment?.images && recommendedObject?.apartment?.images.length > 0 && (
                    <ImageCarousel
                        images={recommendedObject.apartment.images}
                        defaultAltText={getDefaultAltText()}
                        transitionTime={0.2}
                        dragEnabled={false}
                        naturalSlideHeight={1}
                        naturalSlideWidth={1}
                        visibleSlides={1}
                        step={1}
                        borderRadius={borderRadius}
                        aspectRatio={aspectRatio}
                        height={imageCardHeight}
                    />
                )}
            </CarouselContainer>
        </CardContainer>
    )
}

export default memo(ImageCarouselCard)
