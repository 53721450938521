import React, { Fragment, useCallback, useState } from 'react'
import { Container, TextContainer, InnerContainer, CloseIcon } from './pop-up-register-alert-styles'
import { CallbackFunction } from '../../common/types'

interface Props {
    text: string
    visible: boolean
    setAlertVisible: CallbackFunction
}

const PopUpRegisterAlert: React.FC<Props> = ({ text, visible, setAlertVisible }) => {
    const onAnimationEnd = useCallback(() => {
        setAlertVisible(true)
    }, [setAlertVisible])

    return (
        <Fragment>
            {visible && (
                <Container>
                    <InnerContainer isClosed={!visible} onAnimationEnd={onAnimationEnd}>
                        <TextContainer>{text}</TextContainer>
                        <CloseIcon
                            onClick={() => {
                                setAlertVisible(false)
                            }}
                        >
                            ×
                        </CloseIcon>
                    </InnerContainer>
                </Container>
            )}
        </Fragment>
    )
}

export default PopUpRegisterAlert
